<div class="header-avatar">
    <div class="avatar-body"
         *ngIf="user$ | async as user"
         [routerLink]="['/profile']">
        <ion-avatar class="avatar-image">
            <img [src]="getUserAvatar(user.avatar)"/>
        </ion-avatar>
        <div class="avatar-name">{{user.name}}
        </div>
    </div>
</div>

<!--<div (click)="showCalendar()" class="logo">-->
<div class="logo" (click)="goHome()">
    <ion-img [src]="'/assets/images/logo.svg'" class="img"></ion-img>
</div>

<div class="buttons-group" *ngIf="user$ | async as user">

    <button fill="clear" class="badge-button">
        <ion-icon [src]="'/assets/images/icon-beskeder.svg'"></ion-icon>
        <ion-badge color="danger" class="badge" *ngIf="false">3</ion-badge>
    </button>

    <button class="badge-button has-badge">
        <ion-icon [src]="'/assets/images/icon-bell.svg'"></ion-icon>
        <ion-badge color="danger" class="badge" *ngIf="false">3</ion-badge>
    </button>

    <button
        [routerLink]="['/settings']"
        fill="clear"
        class="badge-button last-button">
        <ion-icon [src]="'/assets/images/icon-settings.svg'"></ion-icon>
        <ion-badge color="danger" class="badge" *ngIf="false">3</ion-badge>
    </button>
</div>
