import { Pipe, PipeTransform } from '@angular/core';
import {DateHelper} from "@ypa/helpers/date-helper";

@Pipe({
  name: 'weekDay'
})
export class WeekDayPipe implements PipeTransform {
  transform(value: number): string {
    return DateHelper.getWeekDayByOrderNumber(value);
  }
}
