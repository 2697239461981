import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {WelcomeMessageInterface} from "@ypa/types/welcome-message";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {BaseEntityParams} from "@ypa/types/base-entity";

const name = 'WELCOME_MESSAGES'

@Injectable({providedIn: "root"})
export class WelcomeMessageRepository extends AbstractEntityRepository<WelcomeMessageInterface, BaseEntityParams> {
    protected readonly store = createStore(
        {name},
        withEntities<WelcomeMessageInterface>()
    );
}
