import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseWelcomeMessageInterface, WelcomeMessageInterface} from "@ypa/types/welcome-message";
import {Observable, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "@ypa/constants/environments";
import {WelcomeMessageRepository} from "@ypa/state-management/shared/welcome-message";
import {BaseEntityParams, EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class WelcomeMessageService extends AbstractEntityRepositoryServices<WelcomeMessageInterface, BaseWelcomeMessageInterface, BaseEntityParams> {

    constructor(
        private http: HttpClient,
        private welcomeMessageRepository: WelcomeMessageRepository
    ) {
        super(welcomeMessageRepository);
    }

    protected getAllReq(): Observable<WelcomeMessageInterface[]> {
        return this.http.get<WelcomeMessageInterface[]>(`${environment.apiUrl}/welcome-message`);
    }
    protected createReq(form: Partial<BaseWelcomeMessageInterface>): Observable<WelcomeMessageInterface> {
        return this.http.post<WelcomeMessageInterface>(`${environment.apiUrl}/welcome-message`, form);
    }
    protected updateReq(id: number, form: Partial<WelcomeMessageInterface>): Observable<WelcomeMessageInterface> {
        return this.http.patch<WelcomeMessageInterface>(`${environment.apiUrl}/welcome-message/${id}`, form);
    }
    protected removeReq(id: number): Observable<any> {
        return throwError(() => new Error('Remove method is not implemented in Welcome Message api'));
    }
    protected getByIdReq(id: number): Observable<WelcomeMessageInterface> {
        return throwError(() => new Error('Get by id method is not implemented in Welcome Message api'));
    }
    protected getByReq(params: BaseEntityParams): Observable<WelcomeMessageInterface[]> {
        return this.http.get<WelcomeMessageInterface[]>(`${environment.apiUrl}/welcome-message`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<WelcomeMessageInterface>): Observable<EntityListInterface<WelcomeMessageInterface>> {
        throw new Error("Method not implemented.");
    }
}
