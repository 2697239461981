import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {OnBoardingInterface, OnBoardingParams} from "@ypa/types/on-boarding";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";

const name = 'ON_BOARDING';

@Injectable({providedIn: "root"})
export class OnBoardingRepository extends AbstractEntityRepository<OnBoardingInterface, OnBoardingParams> {
    protected readonly store = createStore(
        {name},
        withEntities<OnBoardingInterface>()
    );
}
