import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {
    BaseBaselineTestInjuryInterface,
    BaselineTestInjuryInterface,
    BaselineTestInjuryParams
} from "@ypa/types/baseline-test-injury";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaselineTestInjuryRepository} from "@ypa/state-management/shared/baseline-test-injury";
import {Observable} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class BaselineTestInjuryService extends AbstractEntityRepositoryServices<BaselineTestInjuryInterface, BaseBaselineTestInjuryInterface, BaselineTestInjuryParams> {

    constructor(
        private http: HttpClient,
        private baselineTestInjuryRepository: BaselineTestInjuryRepository
    ) {
        super(baselineTestInjuryRepository);
    }

    protected createReq(form: Partial<BaselineTestInjuryInterface> | BaseBaselineTestInjuryInterface): Observable<BaselineTestInjuryInterface> {
        return this.http.post<BaselineTestInjuryInterface>(`${environment.apiUrl}/baseline-test-injury`, form);
    }

    protected getAllReq(): Observable<BaselineTestInjuryInterface[]> {
        return this.http.get<BaselineTestInjuryInterface[]>(`${environment.apiUrl}/baseline-test-injury`);
    }

    protected getByIdReq(id: number): Observable<BaselineTestInjuryInterface> {
        return this.http.get<BaselineTestInjuryInterface>(`${environment.apiUrl}/baseline-test-injury/${id}`);
    }

    protected getByReq(params: BaselineTestInjuryParams): Observable<BaselineTestInjuryInterface[]> {
        return this.http.get<BaselineTestInjuryInterface[]>(`${environment.apiUrl}/baseline-test-injury`, {params: params as HttpParams});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/baseline-test-injury/${id}`);
    }

    protected updateReq(id: number, form: Partial<BaselineTestInjuryInterface>): Observable<BaselineTestInjuryInterface> {
        return this.http.put<BaselineTestInjuryInterface>(`${environment.apiUrl}/baseline-test-injury/${id}`, form);
    }

    protected updateListReq(list: EntityListInterface<BaselineTestInjuryInterface>): Observable<EntityListInterface<BaselineTestInjuryInterface>> {
        throw new Error("Method not implemented.");
    }
}
