import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import "reflect-metadata";
import { AppModule } from './app/app.module';
import { environment } from '@ypa/constants/environments';
import {defineCustomElements} from "@ionic/pwa-elements/loader";


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));


defineCustomElements(window).then();
