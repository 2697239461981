import {inject, Injectable} from '@angular/core';
import {RoleMetricRestrictionTagFormInterface} from "@ypa/types/role-metric-restriction-tag";
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {ControlsOf, FormArray, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {LanguageFormService} from "@ypa/data-access/language";

@Injectable()

export class RoleMetricRestrictionTagFormService extends AbstractFormService<RoleMetricRestrictionTagFormInterface> {

    private languageFormService = inject(LanguageFormService);

    getForm(params: Partial<RoleMetricRestrictionTagFormInterface>): FormGroup<ControlsOf<Partial<RoleMetricRestrictionTagFormInterface>>> {

        let form = {};

        if (params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        return this.fb.group({
            ...form,
            ...{
                roleId: this.fb.control(params.roleId),
                metricTagId: this.fb.control(params.metricTagId, Validators.required),
                nameTranslationField: this.fb.group(this.languageFormService.getTranslationControls(params.nameTranslationField)),
                descriptionTranslationField: this.fb.group(this.languageFormService.getTranslationControls(params.descriptionTranslationField)),
                checked: this.fb.control(params.checked)
                // label: this.fb.control(params.label),
                // description: this.fb.control(params.description),
            }
        }) as FormGroup<ControlsOf<Partial<RoleMetricRestrictionTagFormInterface>>>
    }

    getRoleMetricRestrictionTagArray(): FormArray<Partial<RoleMetricRestrictionTagFormInterface>> {
        return this.fb.array<Partial<RoleMetricRestrictionTagFormInterface>>([]);
    }
}
