import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseRestrictionTagInterface, RestrictionTagInterface, RestrictionTagParams} from "@ypa/types/restriction-tag";
import {RestrictionTagRepository} from "@ypa/state-management/shared/restriction-tag";
import {HttpClient} from "@angular/common/http";
import {environment} from "@ypa/constants/environments";
import {Observable} from "rxjs";
import { EntityListInterface } from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class RestrictionTagService extends AbstractEntityRepositoryServices<RestrictionTagInterface, BaseRestrictionTagInterface, RestrictionTagParams> {

    constructor(
        private http: HttpClient,
        private restrictionTagRepository: RestrictionTagRepository
    ) {
        super(restrictionTagRepository);
    }

    protected createReq(form: Partial<RestrictionTagInterface> | BaseRestrictionTagInterface): Observable<RestrictionTagInterface> {
        return this.http.post<RestrictionTagInterface>(`${environment.apiUrl}/restriction-tag`, form);
    }

    protected getAllReq(): Observable<RestrictionTagInterface[]> {
        return this.http.get<RestrictionTagInterface[]>(`${environment.apiUrl}/restriction-tag`);
    }

    protected getByIdReq(id: number): Observable<RestrictionTagInterface> {
        return this.http.get<RestrictionTagInterface>(`${environment.apiUrl}/restriction-tag/${id}`);
    }

    protected getByReq(params: RestrictionTagParams): Observable<RestrictionTagInterface[]> {
        return this.http.get<RestrictionTagInterface[]>(`${environment.apiUrl}/restriction-tag`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/restriction-tag/${id}`);
    }

    protected updateReq(id: number, form: Partial<RestrictionTagInterface>): Observable<RestrictionTagInterface> {
        return this.http.patch<RestrictionTagInterface>(`${environment.apiUrl}/restriction-tag/${id}`, form);
    }

    protected override updateListReq(list: EntityListInterface<RestrictionTagInterface>): Observable<EntityListInterface<RestrictionTagInterface>> {
        throw new Error("Method not implemented.");
    }
}
