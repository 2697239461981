import {Injectable} from '@angular/core';
import {createStore} from '@ngneat/elf';
import {selectManyByPredicate, withEntities} from '@ngneat/elf-entities';
import {MetricReportGroupInterface, MetricReportGroupParams} from '@ypa/types/metric-report-group';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {FormTypeEnum} from "@ypa/enums/form-type";
import {Observable} from "rxjs";
import {FormService} from "@ypa/data-access/form";
import {MetricReportGroupStatusEnum} from "@ypa/enums/metric-report-group-status";

const name = 'METRIC_REPORT_GROUPS'

@Injectable({providedIn: "root"})
export class MetricReportGroupRepository extends AbstractEntityRepository<MetricReportGroupInterface, MetricReportGroupParams> {
    protected readonly store = createStore(
        {name},
        withEntities<MetricReportGroupInterface>()
    );

    constructor(private formService: FormService) {
        super()
    }

    getByDateRangeAndType(from: Date, to: Date, types: FormTypeEnum[], withAllNewFollowUp = false): Observable<MetricReportGroupInterface[]> {
        return this.store.pipe(selectManyByPredicate(report => {
            if (withAllNewFollowUp && report.formType === FormTypeEnum.followUp && report.status === MetricReportGroupStatusEnum.new) {
                return true;
            }

            const isInRange : boolean = typeof report.dateAt !== 'undefined' && from <= report.dateAt && to >= report.dateAt;

            const isRightType : boolean = report.formType && types.some(x => x === report.formType);

            if (from && to && !types.length) {
                return isInRange;
            } else if ((!from || !to) && types.length) {
                return isRightType;
            }
            return (isInRange && isRightType);
        }));
    }

    private checkType(reportType: FormTypeEnum, selectedType: FormTypeEnum | FormTypeEnum[]): boolean {
        if (!reportType || !selectedType) {
            return false;
        }
        if (Array.isArray(selectedType)) {
            return selectedType.some(x => x === reportType);
        } else {
            return reportType === selectedType;
        }
    }
}
