import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class SwipeGestureService {
    private _isEnabled = true;

    get isEnabled(): boolean {
        return this._isEnabled;
    }

    enable() {
        this._isEnabled = true;
    }

    disable() {
        this._isEnabled = false;
    }
}
