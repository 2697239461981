import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {
    BaseCollectorCalculationInterface,
    CollectorCalculationInterface,
    CollectorCalculationParams
} from "@ypa/types/collector-calculation";
import {Observable, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CollectorCalculationRepository} from "@ypa/state-management/shared/collector-calculation";
import {environment} from "@ypa/constants/environments";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class CollectorCalculationService extends AbstractEntityRepositoryServices<CollectorCalculationInterface, BaseCollectorCalculationInterface, CollectorCalculationParams> {

    constructor(
        private http: HttpClient,
        protected collectorCalculationRepository: CollectorCalculationRepository
    ) {
        super(collectorCalculationRepository);
    }

    protected getAllReq(): Observable<CollectorCalculationInterface[]> {
        return this.http.get<CollectorCalculationInterface[]>(`${environment.apiUrl}/collector-calculation`);
    }

    protected createReq(form: BaseCollectorCalculationInterface | Partial<CollectorCalculationInterface>): Observable<CollectorCalculationInterface> {
        return this.http.post<CollectorCalculationInterface>(`${environment.apiUrl}/collector-calculation`, form);
    }

    protected updateReq(id: number, form: Partial<CollectorCalculationInterface>): Observable<CollectorCalculationInterface> {
        return this.http.put<CollectorCalculationInterface>(`${environment.apiUrl}/collector-calculation/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<CollectorCalculationInterface>(`${environment.apiUrl}/collector-calculation/${id}`);
    }

    protected getByIdReq(id: number): Observable<CollectorCalculationInterface> {
        return throwError(() => new Error('Get collector calculation by id api is not implemented'));
    }

    protected getByReq(params: CollectorCalculationParams): Observable<CollectorCalculationInterface[]> {
        return this.http.get<CollectorCalculationInterface[]>(`${environment.apiUrl}/collector-calculation`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<CollectorCalculationInterface>): Observable<EntityListInterface<CollectorCalculationInterface>> {
        throw new Error("Method not implemented.");
    }
}
