import {BaseEntityInterface} from "./base-entity.interface";

export interface BaseEntityClubRelationInterface {
    clubId: number
}
export interface EntityClubRelationInterface extends BaseEntityClubRelationInterface, BaseEntityInterface {
}

export interface BaseEntityClubEnableAllInterface {
    enabled: boolean
}
