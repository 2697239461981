import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {BaselineTestInjuryInterface, BaselineTestInjuryParams} from "@ypa/types/baseline-test-injury";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";

const name = 'BASELINE_TEST_INJURY';

@Injectable({providedIn: "root"})
export class BaselineTestInjuryRepository extends AbstractEntityRepository<BaselineTestInjuryInterface, BaselineTestInjuryParams> {
    protected readonly store = createStore(
        { name },
        withEntities<BaselineTestInjuryInterface>()
    );
}
