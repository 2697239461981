<ion-header>
    @if (guide) {
        <ion-toolbar>
            <ion-title class="modal-title">{{ guide.title }}</ion-title>
        </ion-toolbar>
        <ion-text class="version">{{ guide.version }}</ion-text>
    }
</ion-header>
<ion-content class="ion-padding">
    @if (guide) {
        @if (guide.shortDescription; as shortDescription) {
            <ion-text class="" *ngIf="shortDescription">
                <div [innerHTML]="shortDescription"></div>
            </ion-text>
        }
        @if (guide.media; as media) {
            <div class="media-container">
                @if (isVideo(media.mime)) {
                    <video muted controls class="media">
                        <source [src]="getVideoUrl(media.url)" [type]="media.mime">
                    </video>
                } @else {
                    @if (media.formats) {
                        <img [ngSrc]="media.url"
                             [alt]="media.alternativeText"
                             [width]="media.width"
                             [height]="media.height"
                             class="media"/>
                    } @else {
                        <img [ngSrc]="media.url"
                             [alt]="media.alternativeText"
                             [width]="media.width"
                             [height]="media.height"
                             class="media"/>
                    }
                }
            </div>
        }
    }
</ion-content>
<ion-footer>
    <ion-toolbar>
        <div class="btn-group">
            @if (guide) {
                <ion-button color="primary" (click)="action(guide.id)">
                    {{ 'app.athlete.version.guide.button.title' | translate }}
                </ion-button>
            }
            <ion-button fill="clear" color="light" class="cancel-btn"
                        (click)="cancel()">{{ 'app.coach.shared.cancel' | translate }}
            </ion-button>
        </div>
    </ion-toolbar>
</ion-footer>

