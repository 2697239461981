import { Injectable } from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {BaseEntityParams} from "@ypa/types/base-entity";
import {RolePermissionInterface} from "@ypa/types/role-permission";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";

const name = 'ROLE_PERMISSION';
@Injectable({
  providedIn: 'root'
})
export class RolePermissionRepository extends AbstractEntityRepository<RolePermissionInterface, BaseEntityParams> {
    protected readonly store = createStore({name}, withEntities<RolePermissionInterface>());
}
