import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/main-tabs/main-tabs.module').then((m) => m.MainTabsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'profile',
        loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule),
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./pages/onboarding/routes').then((m) => m.ROUTES),
        canActivate: []
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/login').then((m) => m.LoginModule),
        canActivate: []
    },
    {
        path: 'forgot',
        loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
    },
    {
        path: 'sign-up',
        loadComponent: () => import('./pages/auth/signup-by-phone/components/signup-by-phone/signup-by-phone.component').then((c) => c.SignupByPhoneComponent),
    },
    {
        path: 'sign-up/create-profile',
        loadComponent: () => import('./pages/auth/signup-by-phone/components/create-profile-by-phone/create-profile-by-phone.component').then((c) => c.CreateProfileByPhoneComponent),
    },
    {
        path: 'account',
        loadChildren: () => import('./pages/account').then((m) => m.AccountModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings').then((m) => m.SettingsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'clubs',
        loadChildren: () => import('./pages/clubs').then((m) => m.ClubsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'invitations',
        loadChildren: () => import('./pages/invitations').then((m) => m.InvitationsModule),
        canActivate: [AuthGuard]

    },
    {
        path: 'invitation-consent',
        loadChildren: () => import('./pages/invitation-consent').then((m) => m.InvitationConsentModule),
        canActivate: [AuthGuard]

    },
    {
        path: 'invite',
        loadChildren: () => import('./pages/invite').then((m) => m.InviteModule),

    },
    {
        path: 'form',
        loadChildren: () => import('./pages/form').then((m) => m.FormModule),
    },
    {
        path: 'ui-library',
        loadChildren: () => import('./pages/ui-library/ui-library.module').then((m) => m.UiLibraryModule),
    },
    {
        path: 'timezone',
        loadChildren: () => import('./pages/timezone/user-timezone.module').then((m) => m.UserTimezoneModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'metric-access',
        loadChildren: () => import('./pages/forms/pages/metric-access/metric-access.module').then((m) => m.MetricAccessModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'content',
        loadChildren: () => import('./pages/content').then((m) => m.ContentModule),
    },
    {
        path: 'forms',
        loadChildren: () => import('./pages/forms/forms.module').then((m) => m.FormsModule),
    },
    {
        path: 'forms/change-schedule',
        loadChildren: () => import('./pages/forms/pages/change-schedule/change-schedule.module').then((m) => m.ChangeScheduleModule),
    },
    {
        path: 'forms/skip-schedule',
        loadChildren: () => import('./pages/forms/pages/skip-schedule/skip-schedule.module').then(m => m.SkipScheduleModule)
    },
    {
        path: 'forms/metric-access',
        loadChildren: () => import('./pages/forms/pages/metric-access/metric-access.module').then((m) => m.MetricAccessModule),
    },
    {
        path: 'your-injuries',
        loadChildren: () =>
            import('./pages/injuries/your-injuries/routes').then((m) => m.ROUTES),
    },
    {
        path: 'your-sports',
        loadChildren: () =>
            import('./pages/user-sports/routes').then((m) => m.ROUTES),
    },
    {
        path: 'injuries/add',
        loadChildren: () => import('./pages/injuries/add-injury-flow/routes').then(m => m.ROUTES)
    },
    {
        path: 'version-guides',
        loadChildren: () => import('./pages/app-version-guides/routes').then(m => m.ROUTES)
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            // enableTracing: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
