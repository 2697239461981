import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AppVersionLockerInterface, AppVersionLockerParams} from "@ypa/types/app-version-locker";
import {Observable} from "rxjs";
import {environment} from "@ypa/constants/environments";

@Injectable({providedIn: 'root'})
export class AppVersionLockerService {
    constructor(
        private http: HttpClient
    ) {
    }

    check(params: AppVersionLockerParams): Observable<AppVersionLockerInterface> {
        return this.http.post<AppVersionLockerInterface>(`${environment.apiUrl}/app-version/check-restrictions`, params);
    }
}
