import {Injectable, Injector} from "@angular/core";
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {OnBoardingInterface} from "@ypa/types/on-boarding";
import {ControlsOf, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";

@Injectable()
export class OnBoardingFormService extends AbstractFormService<OnBoardingInterface> {

    getForm(params: Partial<OnBoardingInterface>): FormGroup<ControlsOf<Partial<OnBoardingInterface>>> {
        let form = {};

        if (params && params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        if (params && params.ownerClubId !== null && typeof params.ownerClubId !== "undefined") {
            form = {
                ...form,
                ownerClubId: this.fb.control(params.ownerClubId, [Validators.required]),
            }
        }

        return this.fb.group({
            ...form,
            ...{
                welcomeVideoId: this.fb.control(params && params.welcomeVideoId ? params.welcomeVideoId : ('' as unknown as number), ),
                // nutritionFormId: this.fb.control(params && params.nutritionFormId ? params.nutritionFormId : ('' as unknown as number), ),
                // mentalHealthFormId: this.fb.control(params && params.mentalHealthFormId ? params.mentalHealthFormId : ('' as unknown as number), ),
                // physicalActivityFormId: this.fb.control(params && params.physicalActivityFormId ? params.physicalActivityFormId : ('' as unknown as number), ),
                infoVideoId: this.fb.control(params && params.infoVideoId ? params.infoVideoId : ('' as unknown as number)),
            }
        });
    }

}
