import {ChangeDetectionStrategy, Component, Input, inject} from '@angular/core';
import {IonicModule, ModalController, NavController} from "@ionic/angular";
import {AsyncPipe, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {environment} from "@ypa/constants/environments";
import {AppVersionGuideArticleInterface} from "types/app-version-guide";

@Component({
    selector: 'ypa-app-version-guide-dialog',
    standalone: true,
    imports: [
        IonicModule,
        AsyncPipe,
        NgForOf,
        NgIf,
        TranslateModule,
        NgOptimizedImage,
    ],
    templateUrl: './app-version-guide-dialog.component.html',
    styleUrl: './app-version-guide-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppVersionGuideDialogComponent{

    @Input({required: true}) guide: AppVersionGuideArticleInterface;

    private modalCtrl = inject(ModalController);
    private navCtrl = inject(NavController);

    isVideo(mime: string): boolean {
        return mime.includes('video');
    }

    getVideoUrl(url: string): string {
        return `${environment.contentApiUrl}${url}`;
    }

    action(id: number) {
        this.modalCtrl.dismiss().then();
        this.navCtrl.navigateForward(`/version-guides/item/${id}`).then();
    }

    cancel() {
        return this.modalCtrl.dismiss('cancel');
    }

}
