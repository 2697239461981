import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeekDayPipe } from './pipes/week-day.pipe';

@NgModule({
    declarations: [
        WeekDayPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [WeekDayPipe]
})
export class WeekDayModule {}
