import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {UserOnBoardingStatusInterface} from "@ypa/types/user-on-boarding-status";
import {BaseEntityParams} from "@ypa/types/base-entity";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";

const name = 'USER_ON_BOARDING_STATUS';

@Injectable({providedIn: 'root'})
export class UserOnBoardingStatusRepository extends AbstractEntityRepository<UserOnBoardingStatusInterface, BaseEntityParams>{
    protected readonly store = createStore({name}, withEntities<UserOnBoardingStatusInterface>());
}
