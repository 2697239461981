import { Component, NgZone, OnDestroy, OnInit, signal } from '@angular/core';
import { SystemEventsService } from "@ypa/data-access/system-events";
import { Observable, Subscription, of, switchMap, tap } from "rxjs";
import { AuthTokenRepository } from "@ypa/state-management/shared/auth-token";
import { UserAuthService } from "@ypa/data-access/user";
import { UserTimezoneRepository } from "@ypa/state-management/pwa/timezone";
import { SwipeGestureService } from "./data-access/swipe-gesture.service";
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { ModalController, NavController } from "@ionic/angular";
import { filter } from "rxjs/operators";
import { PushService } from "@ypa/data-access/push";
import { LanguageCurrentService, LanguageService } from "@ypa/data-access/language";
import { CalendarEventsService, CalendarUpdateInitiationTypeEnum } from "@ypa/features/calendar";
import { PwaAppVersionLockerService } from "./data-access/app-version-locker/pwa-app-version-locker.service";
import {
    AppVersionLockerDialogComponent
} from "./features/modals/app-version-locker-dialog/app-version-locker-dialog.component";
import { AllowedFormCancelPeriodService } from "@ypa/data-access/allowed-form-cancel-period";
import { BaselineTestService } from "@ypa/data-access/baseline-test";
import { BaselineTestInjuryService } from "@ypa/data-access/baseline-test-injury";
import { CalculationService } from "@ypa/data-access/calculation";
import { ClubService } from "@ypa/data-access/club";
import { SportClubService } from "@ypa/data-access/sport-club";
import { CollectorService } from "@ypa/data-access/collector";
import { CollectorCalculationService } from "@ypa/data-access/collector-calculation";
import { CurrentClubService } from "@ypa/data-access/current-club";
import { FormService } from "@ypa/data-access/form";
import { FormClubService } from "@ypa/data-access/form-club";
import { InjuryService } from "@ypa/data-access/injury";
import { InvitationService } from "@ypa/data-access/invitation";
import { MetricService } from "@ypa/data-access/metric";
import { MetricClubService } from "@ypa/data-access/metric-club";
import { MetricHintService } from "@ypa/data-access/metric-hint";
import { MetricHintTextService } from "@ypa/data-access/metric-hint-text";
import { MetricReportService } from "@ypa/data-access/metric-report";
import { MetricReportGroupService } from "@ypa/data-access/metric-report-group";
import { MetricTagService } from "@ypa/data-access/metric-tag";
import { OnBoardingService } from "@ypa/data-access/on-boarding";
import { ReminderService } from "@ypa/data-access/reminder";
import { RestrictionTagService } from "@ypa/data-access/restriction-tag";
import { RoleService } from "@ypa/data-access/role";
import { UserDashboardMetricService } from "@ypa/data-access/user-dashboard-metric";
import { UserDashboardMetricClubService } from "@ypa/data-access/user-dashboard-metric-club";
import { UserRoleService } from "@ypa/data-access/user-role";
import { WelcomeMessageService } from "@ypa/data-access/shared/welcome-message";
import { RolePermissionService } from "@ypa/data-access/role-permission";
import { RoleMetricRestrictionTagService } from "@ypa/data-access/role-metric-restriction-tag";
import { UserOnBoardingStatusService } from "@ypa/data-access/pwa/user-on-boarding-status";
import { MixpanelAnalyticsService } from '@ypa/libs/analytics';
import { AppVersionGuideService } from "./data-access/app-version-guide/app-version-guide.service";
import {
    AppVersionGuideDialogComponent
} from "./features/modals/app-version-guide-dialog/app-version-guide-dialog.component";
import { UserInterface } from "@ypa/types/user";
import { AppVersionGuideArticleInterface } from "types/app-version-guide";
import { NavigationEnd, Router } from '@angular/router';
import { ClubInterface } from '@ypa/types/club';
import {environment} from "@ypa/constants/environments";

@Component({
    selector: 'ypa-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

    private subscription = new Subscription();

    private user = signal<UserInterface>(null);

    constructor(
        public swipeGestureService: SwipeGestureService,
        private systemEventsService: SystemEventsService,
        private calendarEventsService: CalendarEventsService,
        private allowedFormCancelPeriodService: AllowedFormCancelPeriodService,
        private authTokenRepository: AuthTokenRepository,
        private baselineTestService: BaselineTestService,
        private baselineTestInjuryService: BaselineTestInjuryService,
        private calculationService: CalculationService,
        private clubService: ClubService,
        private sportClubService: SportClubService,
        private collectorService: CollectorService,
        private collectorCalculationService: CollectorCalculationService,
        private currentClubService: CurrentClubService,
        private formService: FormService,
        private formClubService: FormClubService,
        private injuryService: InjuryService,
        private invitationService: InvitationService,
        private metricService: MetricService,
        private metricClubService: MetricClubService,
        private metricHintService: MetricHintService,
        private metricHintTextService: MetricHintTextService,
        private metricReportService: MetricReportService,
        private metricReportGroupService: MetricReportGroupService,
        private metricTagService: MetricTagService,
        private onBoardingService: OnBoardingService,
        private reminderService: ReminderService,
        private restrictionTagService: RestrictionTagService,
        private roleService: RoleService,
        private userDashboardMetricService: UserDashboardMetricService,
        private userDashboardMetricClubService: UserDashboardMetricClubService,
        private userRoleService: UserRoleService,
        private userTimezoneRepository: UserTimezoneRepository,
        private welcomeMessageService: WelcomeMessageService,
        private rolePermissionService: RolePermissionService,
        private roleMetricRestrictionTagService: RoleMetricRestrictionTagService,
        private userAuthService: UserAuthService,
        private languageService: LanguageService,
        private userOnBoardingStatusService: UserOnBoardingStatusService,
        private zone: NgZone,
        private navCtrl: NavController,
        private pushService: PushService,
        private languageCurrentService: LanguageCurrentService,
        private pwaAppVersionLockerService: PwaAppVersionLockerService,
        private appVersionGuideService: AppVersionGuideService,
        private modalCtrl: ModalController,
        private router: Router,
        private mixpanelAnalyticsService: MixpanelAnalyticsService
    ) {
        this.initializeApp();
    }

    ngOnInit() {
        this.handleLogoutEvent();
        this.handlePush();
        this.setUserUiLanguage();
        this.listenLoginEvent();
        this.checkAppVersionLocker();
        this.checkAppVersionGuide();
        
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            // Call trackPageView with each new route
            // TODO missing clubId
            this.mixpanelAnalyticsService.trackPageViewWithUserId('pwa', this.user()?.id, event.urlAfterRedirects);
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private initializeApp() {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                const slug = event.url.replace('https://redirect.bricksperformanceapp.dk', '');
                if (slug) {
                    this.navCtrl.navigateForward(slug).then();
                }
            });
        });
    }

    private handlePush() {
        this.pushService.init();

        this.subscription.add(
            this.userAuthService.data$.pipe(
                filter(user => !!user),
                switchMap(() => this.pushService.token$),
                filter(token => !!token),
                switchMap(token => this.userAuthService.sendPushToken(token))
            ).subscribe()
        )

        this.subscription.add(
            this.pushService.notification$.pipe(
                tap(() => {
                    this.calendarEventsService.updateStart(CalendarUpdateInitiationTypeEnum.pushNotification);
                })
            ).subscribe()
        );
    }

    private listenLoginEvent() {
        this.subscription.add(
            this.systemEventsService.login$.pipe(
                tap(() => {
                    this.calendarEventsService.updateStart(CalendarUpdateInitiationTypeEnum.dateChange);
                })
            ).subscribe()
        );
    }

    private setUserUiLanguage() {
        const sub = this.userAuthService.data$.pipe(
            filter(user => !!user),
            tap((user) => {
                this.languageCurrentService.setCurrentLanguageByCode(user.uiLanguageId);
            }),
        ).subscribe();
        this.subscription.add(sub);
    }

    private checkAppVersionLocker() {
        this.subscription.add(
            this.pwaAppVersionLockerService.check().pipe(
                tap(data => {
                    this.modalCtrl.create({
                        component: AppVersionLockerDialogComponent,
                        cssClass: 'ion-modal transparent-modal',
                        componentProps: {
                            data
                        },
                        backdropDismiss: false
                    }).then(modal => modal.present())
                })
            ).subscribe()
        )
    }

    private checkAppVersionGuide() {
        const sub = this.userAuthService.data$.pipe(
            filter(user => !!user),// if user logged
            switchMap((user) => {
                this.user.set(user);
                return this.appVersionGuideService.getAppVersion();
            }),
            filter((version) => version !== localStorage.getItem('appVersion')),
            tap((version) => localStorage.setItem('appVersion', version)),
            switchMap((version) => {
                return this.appVersionGuideService.getAppCurrentVersionGuideByVersion(version, this.user().uiLanguage.code).pipe(
                    filter((res) => !!res.list.length),
                    tap((guide) => {
                        this.appVersionModal(guide.list[0]);
                    })
                )
            }),
        ).subscribe();
        this.subscription.add(sub);
    }

    private appVersionModal(guide: AppVersionGuideArticleInterface) {
        this.modalCtrl.create({
            component: AppVersionGuideDialogComponent,
            cssClass: 'ion-modal',
            componentProps: {
                guide
            },
            backdropDismiss: false
        }).then(modal => modal.present())
    }

    /**
     * on logout event
     * @private
     */
    private handleLogoutEvent() {
        this.subscription.add(
            this.systemEventsService.logout$.pipe(
                switchMap(() => this.userAuthService.logout()),
                // catchError(() => of(true))
            ).subscribe(() => {
                this.allowedFormCancelPeriodService.clear();
                this.authTokenRepository.clear();
                this.baselineTestService.clear();
                this.baselineTestInjuryService.clear();
                this.calculationService.clear();
                this.clubService.clear();
                this.sportClubService.clear();
                this.collectorService.clear();
                this.collectorCalculationService.clear();
                this.currentClubService.clear();
                this.formService.clear()
                this.formClubService.clear();
                this.injuryService.clear();
                this.invitationService.clear();
                this.metricService.clear();
                this.metricClubService.clear();
                this.metricHintService.clear();
                this.metricHintTextService.clear();
                this.metricReportService.clear();
                this.metricReportGroupService.clear();
                this.metricTagService.clear();
                this.onBoardingService.clear();
                this.reminderService.clear();
                this.restrictionTagService.clear();
                this.roleService.clear()
                this.userAuthService.clear();
                this.userDashboardMetricService.clear()
                this.userDashboardMetricClubService.clear()
                this.userRoleService.clear()
                this.userTimezoneRepository.clear()
                this.welcomeMessageService.clear()
                this.rolePermissionService.clear();
                this.roleMetricRestrictionTagService.clear();
                this.languageService.clear();
                this.userOnBoardingStatusService.clear();
                this.mixpanelAnalyticsService.clearUserId();
                // add repositories which should be cleaned on logout
            })
        );
    }

    isOnboardingRoute(): boolean {
        return this.router.url.includes('onboarding');
    }
}
