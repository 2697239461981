import { Injectable } from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {BaseEntityParams} from "@ypa/types/base-entity";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {RoleMetricRestrictionTagInterface} from "@ypa/types/role-metric-restriction-tag";

const name = 'ROLE_METRIC_RESTRICTION_TAG';
@Injectable({
  providedIn: 'root'
})
export class RoleMetricRestrictionTagRepository  extends AbstractEntityRepository<RoleMetricRestrictionTagInterface, BaseEntityParams> {
    protected readonly store = createStore({name}, withEntities<RoleMetricRestrictionTagInterface>());
}
