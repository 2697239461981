import {Injectable} from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {AllowedFormCancelPeriodInterface, AllowedFormCancelPeriodParams} from "@ypa/types/allowed-form-cancel-period";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";

const name = 'ALLOWED_FORM_CANCEL_PERIOD';

@Injectable({providedIn: 'root'})
export class AllowedFormCancelPeriodRepository extends AbstractEntityRepository<AllowedFormCancelPeriodInterface, AllowedFormCancelPeriodParams>{
    protected readonly store = createStore(
        {name},
        withEntities<AllowedFormCancelPeriodInterface>()
    );
}
