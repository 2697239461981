<div class='mobile-app-customization bg-default'>
    <ion-icon style="float: right;" (click)="closeModal()" name="close-outline"></ion-icon>
    <H2 *ngIf="data.title" class="modal-title">{{data.title}}</H2>
    <div class="ion-padding">
        <ion-text class="modal-title-descr" *ngIf="data.description">{{data.description}}</ion-text>
        <div class="btn-group">
            <ion-button *ngFor="let button of data.buttons"
                        [color]="getButtonColor(button)"
                        (click)="action(button)"
            >{{button.title}}</ion-button>
        </div>
    </div>
</div>
