import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseUserOnBoardingStatusInterface, UserOnBoardingStatusInterface} from "@ypa/types/user-on-boarding-status";
import {BaseEntityParams, EntityListInterface} from "@ypa/types/base-entity";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {UserOnBoardingStatusRepository} from "@ypa/state-management/pwa/user-on-boarding-status";
import {catchError, Observable, of, tap, throwError} from "rxjs";
import {environment} from "@ypa/constants/environments";

@Injectable({providedIn: "root"})
export class UserOnBoardingStatusService extends AbstractEntityRepositoryServices<UserOnBoardingStatusInterface, BaseUserOnBoardingStatusInterface, BaseEntityParams> {

    constructor(
        private http: HttpClient,
        private userOnBoardingStatusRepository: UserOnBoardingStatusRepository
    ) {
        super(userOnBoardingStatusRepository);
    }

    protected createReq(form: Partial<UserOnBoardingStatusInterface> | BaseUserOnBoardingStatusInterface): Observable<UserOnBoardingStatusInterface> {
        return this.http.post<UserOnBoardingStatusInterface>(`${environment.apiUrl}/user-on-boarding-status`, form);
        // return of(form) as Observable<UserOnBoardingStatusInterface>; //todo implement
    }

    protected getAllReq(): Observable<UserOnBoardingStatusInterface[]> {
        return this.http.get<UserOnBoardingStatusInterface[]>(`${environment.apiUrl}/user-on-boarding-status`).pipe(
            // it's done for on boarding flow, if user starts onboarding he cant get data because no token, so empty list should be returned if 401
            catchError((error) => {
                console.error(error);
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return of([])
                }

                return throwError(error);
            })
        );
    }

    protected getByIdReq(id: number): Observable<UserOnBoardingStatusInterface> {
        return this.http.get<UserOnBoardingStatusInterface>(`${environment.apiUrl}/user-on-boarding-status/${id}`);
    }

    protected getByReq(params: BaseEntityParams): Observable<UserOnBoardingStatusInterface[]> {
        return this.http.get<UserOnBoardingStatusInterface[]>(`${environment.apiUrl}/user-on-boarding-status`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/user-on-boarding-status/${id}`);
    }

    protected updateReq(id: number, form: Partial<UserOnBoardingStatusInterface>): Observable<UserOnBoardingStatusInterface> {
        // its fix of update which should trigger repository event to initiate go forward on the onboarding flow
        if (form.id) {
            console.error('Update user onboarding status api is not implemented');
            return of(form as UserOnBoardingStatusInterface);
        }
        return throwError(() => new Error('Update user onboarding status api is not implemented'))
    }

    protected updateListReq(list: EntityListInterface<UserOnBoardingStatusInterface>): Observable<EntityListInterface<UserOnBoardingStatusInterface>> {
        throw new Error("Method not implemented.");
    }
}
