import {Injectable} from '@angular/core';
import {createStore} from '@ngneat/elf';
import {selectEntityByPredicate, withEntities,} from '@ngneat/elf-entities';
import {InvitationInterface, InvitationParams} from '@ypa/types/invitation';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {Observable} from "rxjs";

const name = 'INVITATIONS';

@Injectable({providedIn: "root"})
export class InvitationRepository extends AbstractEntityRepository<InvitationInterface, InvitationParams> {

    protected readonly store = createStore({name}, withEntities<InvitationInterface>());

    getByToken(token: string): Observable<InvitationInterface> {
        return this.store.pipe(selectEntityByPredicate(invitation => invitation.token === token))
    }
 }
