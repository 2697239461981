import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from "@ionic/angular";

@Component({
    selector: 'ypa-loader',
    standalone: true,
    imports: [CommonModule, IonicModule],
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
    @Input() size: 'small' | 'default' = 'default';

    @HostBinding('class') get class() {
        return this.size;
    }
}
