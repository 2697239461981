import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree, } from '@angular/router';
import { map, Observable } from 'rxjs';
import {UserAuthService} from "@ypa/data-access/user";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private userAuthService: UserAuthService
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.userAuthService.get().pipe(
            map((user) => {
                // if(user) {
                //     return true;
                // }
                if (user && user.isOnboardingCompleted) {
                    return true;
                } else if (user && !user.isOnboardingCompleted) {
                    return this.router.createUrlTree(['/onboarding']);
                }

                return this.router.createUrlTree(['/auth']);
            })
        );
    }
}
