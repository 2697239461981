<ion-app>
    <ion-header>
        <ypa-header></ypa-header>
        <ypa-calendar *ngIf="!isOnboardingRoute()"></ypa-calendar>
    </ion-header>


    <ion-content [scrollY]="false">
        <ion-router-outlet
            [swipeGesture]="swipeGestureService.isEnabled"
        ></ion-router-outlet>
    </ion-content>

<!--    <ion-footer *ngIf="profile$ | async as profile">-->
<!--        <ypa-footer></ypa-footer>-->
<!--    </ion-footer>-->
<!--    <ion-footer>-->
<!--        <ypa-footer></ypa-footer>-->
<!--    </ion-footer>-->
</ion-app>
