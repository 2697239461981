import {FormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ValidationMessageInterface} from '@ypa/types/validation-message';

@Injectable()
export class FormHelper {
    setErrors(group: FormGroup, error: HttpErrorResponse) {
        const messages: ValidationMessageInterface[] = error.error.message;
        // toDo errors
        if(Array.isArray(messages)) {
            messages.forEach(message => {
                if (group.get(message.property)) {
                    group.get(message.property).setErrors(message.constraints);
                }
            });
        }else{
            console.log(messages);
        }
    }
}
