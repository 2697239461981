import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseEntityParams, EntityListInterface} from "@ypa/types/base-entity";
import {InjuryRepository} from "@ypa/state-management/shared/injury";
import {Observable, tap} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {HttpClient} from "@angular/common/http";
import {BaseInjuryInterface, InjuryInterface} from "@ypa/types/injury";

@Injectable({providedIn: "root"})
export class InjuryService extends AbstractEntityRepositoryServices<InjuryInterface, BaseInjuryInterface, BaseEntityParams> {

    constructor(
        private http: HttpClient,
        private injuryRepository: InjuryRepository
    ) {
        super(injuryRepository);
    }

    protected createReq(form: Partial<InjuryInterface> | BaseInjuryInterface): Observable<InjuryInterface> {
        return this.http.post<InjuryInterface>(`${environment.apiUrl}/injury`, form);
    }

    protected getAllReq(): Observable<InjuryInterface[]> {
        return this.http.get<InjuryInterface[]>(`${environment.apiUrl}/injury`);
    }

    protected getByIdReq(id: number): Observable<InjuryInterface> {
        return this.http.get<InjuryInterface>(`${environment.apiUrl}/injury/${id}`);
    }

    protected getByReq(params: BaseEntityParams): Observable<InjuryInterface[]> {
        return this.http.get<InjuryInterface[]>(`${environment.apiUrl}/injury`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/injury/${id}`);
    }

    protected updateReq(id: number, form: Partial<InjuryInterface>): Observable<InjuryInterface> {
        return this.http.put<InjuryInterface>(`${environment.apiUrl}/injury/${id}`, form);
    }

    protected updateListReq(list: EntityListInterface<InjuryInterface>): Observable<EntityListInterface<InjuryInterface>> {
        throw new Error("Method not implemented.");
    }
}
