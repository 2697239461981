import {Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy} from '@angular/core';
import {filter, Observable, Subscription} from "rxjs";
import {CalendarNextActionTypeEnum} from "../../enums/calendar-next-action-type.enum";
import {MyCalendarService} from "./../../services/my-calendar.service";
import {UserAuthService, UserService} from "@ypa/data-access/user";
import {LanguageCodeEnum} from "@ypa/enums/language-code";
import {environment} from "@ypa/constants/environments";


@Component({
    selector: 'ypa-calendar-date',
    templateUrl: './calendar-date.component.html',
    styleUrls: ['./calendar-date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarDateComponent implements OnInit, OnDestroy {
    @Input() dateFormat = 'EEEE dd MMMM';
    @Input() isPrevForwardButtons = true;
    @Input() isShowCalendarButton = true;
    @Input() nextActionType: CalendarNextActionTypeEnum = CalendarNextActionTypeEnum.day;

    isCalendarShown$: Observable<boolean>;

    date$: Observable<Date>;

    userLocale: string;

    private subscription = new Subscription()

    constructor(
        private myCalendarService: MyCalendarService,
        private userAuthService: UserAuthService
    ) {
        this.date$ = myCalendarService.selectedDate$;
        this.isCalendarShown$ = myCalendarService.isShown$
    }

    ngOnInit() {
        const sub = this.userAuthService.get().pipe(
            filter(user => !!user)
        ).subscribe((user) => {
            this.userLocale = user && user.uiLanguage && user.uiLanguage?.code ? user.uiLanguage?.code : environment.defaultLanguage;
        });
        this.subscription.add(sub);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }

    next() {
        this.myCalendarService.next(this.nextActionType);
    }

    prev() {
        this.myCalendarService.previous(this.nextActionType);
    }

    showCalendar() {
        this.myCalendarService.show();
    }

    // shownCalendar(){
    //     this.myCalendarService.isShown$.subscribe((state)=>{
    //         this.isCalendarShown = state
    //     })
    // }

}
