import {inject, Injectable} from "@angular/core";
import {ControlsOf, FormGroup} from "@ngneat/reactive-forms";
import {ClubReminderInterface} from "@ypa/types/club-reminder";
import {ClubReminderTypeEnum} from "@ypa/enums/club-reminder-type";
import {Validators} from "@angular/forms";
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {LanguageInterface} from "@ypa/types/language";
import {LanguageFormService} from "@ypa/data-access/language";

@Injectable()
export class ReminderFormService extends AbstractFormService<ClubReminderInterface> {

    private languageFormService = inject(LanguageFormService);

    getForm(params: ClubReminderInterface, uiLanguages?: LanguageInterface[]): FormGroup<Partial<ControlsOf<ClubReminderInterface>>> {
        let idForm = {};
        if (params.id !== null && typeof params.id !== "undefined") {
            idForm = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.fb.group({
            ...idForm,
            ...{
                clubId: this.fb.control(params.clubId ? params.clubId : null, [Validators.required]),
                type: this.fb.control(params.type ? params.type : ClubReminderTypeEnum.unreportedFormsForTheWholeDay, [Validators.required]),
                time: this.fb.control(params.time !== null && typeof params.time !== "undefined" ? params.time : null, [Validators.required]),
                nameTranslationField: this.fb.group(this.languageFormService.getTranslationControls(params.nameTranslationField)),
                // name: this.fb.control(params.nameTranslationField !== null && typeof params.nameTranslationField !== "undefined" ? params.nameTranslationField : null, [Validators.required])
            }
        });
    }
}
