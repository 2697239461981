import { Injectable } from '@angular/core';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseRoleInterface} from "@ypa/types/role";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {BaseEntityParams, EntityListInterface} from "@ypa/types/base-entity";
import {RolePermissionInterface, RolePermissionParams} from "@ypa/types/role-permission";
import {RolePermissionRepository} from "@ypa/state-management/shared/role-permission";

@Injectable({providedIn: "root"})
export class RolePermissionService extends AbstractEntityRepositoryServices<RolePermissionInterface, BaseRoleInterface, RolePermissionParams> {

    constructor(
        private http: HttpClient,
        private rolePermissionRepository: RolePermissionRepository
    ) {
        super(rolePermissionRepository)
    }

    protected getAllReq(): Observable<RolePermissionInterface[]> {
        return this.http.get<RolePermissionInterface[]>(`${environment.apiUrl}/role-permission`);
    }

    protected createReq(form: BaseRoleInterface | Partial<RolePermissionInterface>): Observable<RolePermissionInterface> {
        return this.http.post<RolePermissionInterface>(`${environment.apiUrl}/role-permission`, form);
    }

    protected updateReq(id: number, form: Partial<RolePermissionInterface>): Observable<RolePermissionInterface> {
        return this.http.post<RolePermissionInterface>(`${environment.apiUrl}/role-permission/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.get<null>(`${environment.apiUrl}/role-permission/${id}`);
    }

    protected getByIdReq(id: number): Observable<RolePermissionInterface> {
        return this.http.get<RolePermissionInterface>(`${environment.apiUrl}/role-permission/${id}`);
    }

    protected getByReq(params: BaseEntityParams): Observable<RolePermissionInterface[]> {
        return this.http.get<RolePermissionInterface[]>(`${environment.apiUrl}/role-permission`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<RolePermissionInterface>): Observable<EntityListInterface<RolePermissionInterface>> {
        throw new Error('Method not implemented.');
    }
}
