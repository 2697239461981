import {Component, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {UserInterface} from '@ypa/types/user';
import {UserAuthService} from "@ypa/data-access/user";
import {environment} from "@ypa/constants/environments";

@Component({
    selector: 'ypa-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    user$: Observable<UserInterface>;
    private readonly noImg = '/assets/images/no-avatar.svg';

    constructor(
        private navController: NavController,
        private userAuthService: UserAuthService
    ) {
    }

    goHome() {
        this.navController.navigateRoot('/').then()
    }

    ngOnInit(): void {
        this.user$ = this.userAuthService.get();
    }

    getUserAvatar(path: string): string {
        return path ? `${environment.apiUrl}${path}` : this.noImg;
    }

}
