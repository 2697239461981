import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {CalendarUpdateInitiationTypeEnum} from "../enums/calendar-update-initiation-type.enum";

@Injectable({providedIn: "root"})
export class CalendarEventsService {
    updateInitiation$: Observable<CalendarUpdateInitiationTypeEnum>;
    isUpdating$: Observable<boolean>;
    private updateInitiationSubject = new BehaviorSubject(CalendarUpdateInitiationTypeEnum.dateChange);
    private isUpdatingSubject = new BehaviorSubject(false);

    constructor() {
        this.updateInitiation$ = this.updateInitiationSubject.asObservable();
        this.isUpdating$ = this.isUpdatingSubject.asObservable();
    }

    updateStart(type = CalendarUpdateInitiationTypeEnum.dateChange) {
        if (!this.isUpdatingSubject.value) {
            this.isUpdatingSubject.next(true);
            this.updateInitiationSubject.next(type);
        } else {
            console.log('CalendarEventsService updating')
        }
    }

    updateEnd() {
        if (this.isUpdatingSubject.value) {
            this.isUpdatingSubject.next(false);
        }
    }
}
