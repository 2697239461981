import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppVersionLockerButtonInterface, AppVersionLockerInterface} from "@ypa/types/app-version-locker";
import {IonicModule, ModalController, NavController} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {AppVersionLockerButtonActionEnum} from "@ypa/enums/app-version-locker-button-action";
import {Browser} from '@capacitor/browser';

@Component({
    selector: 'ypa-app-version-locker-dialog',
    standalone: true,
    imports: [CommonModule, IonicModule, TranslateModule],
    templateUrl: './app-version-locker-dialog.component.html',
    styleUrls: ['./app-version-locker-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppVersionLockerDialogComponent {
    @Input() data: AppVersionLockerInterface;

    constructor(
        private modalCtrl: ModalController,
        private navCtrl: NavController
    ) {
    }

    getButtonColor(button: AppVersionLockerButtonInterface): string {
        if (button.action === AppVersionLockerButtonActionEnum.close) {
            return 'light';
        }
        return 'primary';
    }

    action(button: AppVersionLockerButtonInterface) {
        switch (button.action) {
            case AppVersionLockerButtonActionEnum.close:
                this.modalCtrl.dismiss().then();
                break;
            case AppVersionLockerButtonActionEnum.internalRedirect:
                this.modalCtrl.dismiss().then();
                this.navCtrl.navigateForward(button.actionData).then();
                break;
            case AppVersionLockerButtonActionEnum.link:
                Browser.open({url: button.actionData}).then();
                break;
            default:
                break;
        }
    }

    closeModal(){
        this.modalCtrl.dismiss().then();
    }
}
