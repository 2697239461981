import {BaseMetricReportInterface} from "@ypa/types/metric-report";

export interface ImportReportGroupInterface {
    reportGroups: ImportReportInterface[];
}

export interface ImportReportInterface {
    dateAt: string;
    formId: number;
    userId: number;
    timetableReportId?: number;
    reports: BaseMetricReportInterface[];
    id?: number;  // group report id using for complete metric report group
}
