import {Injectable} from '@angular/core';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {
    AllowedFormCancelPeriodInterface,
    AllowedFormCancelPeriodParams,
    BaseAllowedFormCancelPeriodInterface
} from "@ypa/types/allowed-form-cancel-period";
import {HttpClient} from "@angular/common/http";
import {AllowedFormCancelPeriodRepository} from "@ypa/state-management/shared/allowed-form-cancel-period";
import {Observable} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {EntityListInterface} from '@ypa/types/base-entity';

@Injectable({providedIn: "root"})
export class AllowedFormCancelPeriodService extends AbstractEntityRepositoryServices<AllowedFormCancelPeriodInterface, BaseAllowedFormCancelPeriodInterface, AllowedFormCancelPeriodParams> {

    constructor(
        private http: HttpClient,
        private allowedFormCancelPeriodRepository: AllowedFormCancelPeriodRepository
    ) {
        super(allowedFormCancelPeriodRepository);
    }

    protected createReq(form: Partial<AllowedFormCancelPeriodInterface> | BaseAllowedFormCancelPeriodInterface): Observable<AllowedFormCancelPeriodInterface> {
        return this.http.post<AllowedFormCancelPeriodInterface>(`${environment.apiUrl}/allowed-form-cancel-period`, form);
    }

    protected getAllReq(): Observable<AllowedFormCancelPeriodInterface[]> {
        return this.http.get<AllowedFormCancelPeriodInterface[]>(`${environment.apiUrl}/allowed-form-cancel-period`);
    }

    protected getByIdReq(id: number): Observable<AllowedFormCancelPeriodInterface> {
        return this.http.get<AllowedFormCancelPeriodInterface>(`${environment.apiUrl}/allowed-form-cancel-period/${id}`);
    }

    protected getByReq(params: AllowedFormCancelPeriodParams): Observable<AllowedFormCancelPeriodInterface[]> {
        return this.http.get<AllowedFormCancelPeriodInterface[]>(`${environment.apiUrl}/allowed-form-cancel-period`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/allowed-form-cancel-period/${id}`);
    }

    protected updateReq(id: number, form: Partial<AllowedFormCancelPeriodInterface>): Observable<AllowedFormCancelPeriodInterface> {
        return this.http.post<AllowedFormCancelPeriodInterface>(`${environment.apiUrl}/allowed-form-cancel-period/${id}`, form);
    }

    protected updateListReq(list: EntityListInterface<AllowedFormCancelPeriodInterface>): Observable<EntityListInterface<AllowedFormCancelPeriodInterface>> {
        throw new Error('Method not implemented.');
    }
}
