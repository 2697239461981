import {Injectable} from "@angular/core";
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {UiBaselineTestInjuryInterface} from "@ypa/types/baseline-test-injury";
import {ControlsOf, FormArray, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";

@Injectable()
export class BaselineTestInjuryFormService extends AbstractFormService<UiBaselineTestInjuryInterface> {
    getForm(params: Partial<UiBaselineTestInjuryInterface>): FormGroup<ControlsOf<Partial<UiBaselineTestInjuryInterface>>> {
        let form = {};

        if (params && params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        return this.fb.group({
            ...form,
            ...{
                baselineTestId: this.fb.control(params && params.baselineTestId ? params.baselineTestId : ('' as unknown as number)),
                injuryId: this.fb.control(params && params.injuryId ? params.injuryId : ('' as unknown as number), [Validators.required]),
                deleted: this.fb.control(false)
            }
        });
    }


    getFormArray(): FormArray<Partial<UiBaselineTestInjuryInterface>> {
        return this.fb.array([]);
    }
}
