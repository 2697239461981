import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorsComponent } from './form-errors.component';
import {IonicModule} from "@ionic/angular";



@NgModule({
    declarations: [
        FormErrorsComponent
    ],
    exports: [
        FormErrorsComponent
    ],
    imports: [
        CommonModule,
        IonicModule
    ]
})
export class FormErrorsModule { }
