import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {CollectorCalculationInterface, CollectorCalculationParams} from "@ypa/types/collector-calculation";
import {createStore} from "@ngneat/elf";
import {selectManyByPredicate, withActiveId, withEntities} from "@ngneat/elf-entities";
import {Observable} from "rxjs";
import {BaseEntityParams} from "@ypa/types/base-entity";

const name = 'COLLECTOR_CALCULATION';

@Injectable({providedIn: "root"})
export class CollectorCalculationRepository extends AbstractEntityRepository<CollectorCalculationInterface, CollectorCalculationParams> {
    protected readonly store = createStore(
        {name},
        withEntities<CollectorCalculationInterface>(),
        withActiveId(),
    );
}
