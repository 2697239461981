import {Injectable, NgZone} from "@angular/core";
import {ActionPerformed} from "@capacitor/push-notifications";
import {NavController} from "@ionic/angular";
import {UserAuthService} from "@ypa/data-access/user";
import {filter, first, tap} from "rxjs";

@Injectable({providedIn: 'root'})
export class PushActionService {

    constructor(
        private navCtrl: NavController,
        private userAuthService: UserAuthService,
        private zone: NgZone
    ) {
    }

    /**
     * handle pushMessage action
     * @param customData
     */
    handlePushMessageAction(notification: ActionPerformed) {
        // alert('Push action performed: ' + JSON.stringify(notification));
        this.openForm(notification);
    }

    private openForm(notification: ActionPerformed) {
        this.userAuthService.data$.pipe(
            first(user => !!user),
            filter(user => !!user),
            tap(() => {
                this.zone.run(() => {
                    return this.navCtrl.navigateRoot('/').then(() => {
                        return this.navCtrl.navigateForward(`/form/${notification.notification.data.formId}?group=${notification.notification.data.groupId}`)
                    })
                }).then()
            })
        ).subscribe();
    }
}
