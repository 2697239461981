import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {environment} from '@ypa/constants/environments';
import {from, map, Observable, of, switchMap, tap} from 'rxjs';
import {
    AppVersionGuideArticleInterface,
    AppVesrionListIntefrace
} from "types/app-version-guide";
import {App} from "@capacitor/app";


@Injectable()

export class AppVersionGuideService {

    private http = inject(HttpClient);
    private params = {
        populate: 'deep,5',
    };

    getAppVersion(): Observable<string> {
        return from(App.getInfo()).pipe(
            map((appInfo) => {
                return appInfo.version
            }),
        )
    }

    getAppVersionsGuidesList(locale: string): Observable<AppVesrionListIntefrace> {
        const paramsData = {
            ...this.params
        };
        const params = new HttpParams({fromObject: paramsData});
        return this.http.get<AppVesrionListIntefrace>(`${environment.contentApiUrl}/api/app-version-guides?locale=${locale}`, {params});
    }

    getAppCurrentVersionGuideByVersion(appVersion: string, locale: string): Observable<AppVesrionListIntefrace> {
        const paramsData = {
            ...this.params,
            'filters[version][$eq]': appVersion
        };
        const params = new HttpParams({fromObject: paramsData});
        return this.http.get<AppVesrionListIntefrace>(`${environment.contentApiUrl}/api/app-version-guides?locale=${locale}`, {params});
    }

    getAppCurrentVersionGuideById(id: number): Observable<AppVersionGuideArticleInterface> {
        const paramsData = {
            ...this.params
        };
        const params = new HttpParams({fromObject: paramsData});
        return this.http.get<AppVersionGuideArticleInterface>(`${environment.contentApiUrl}/api/app-version-guides/${id}`, {params});
    }

}
