import {AppVersionLockerService} from "@ypa/data-access/app-version-locker";
import {from, Observable, of, switchMap} from "rxjs";
import {AppVersionLockerInterface} from "@ypa/types/app-version-locker";
import {App} from "@capacitor/app";
import {Device} from '@capacitor/device';
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class PwaAppVersionLockerService {
    constructor(
        private appVersionLockerService: AppVersionLockerService
    ) {
    }

    check(): Observable<AppVersionLockerInterface> {
        // return of({
        //     title: 'Your app version is out of date!!!',
        //     description: 'Please update  your application version to be able to use it',
        //     buttons: [
        //         {
        //             title: 'cancel',
        //             action: AppVersionLockerButtonActionEnum.close
        //         },
        //         {
        //             title: 'Open ios store',
        //             action: AppVersionLockerButtonActionEnum.link,
        //             actionData: 'https://apps.apple.com/us/app/bricks-performance-app/id6449764032'
        //         },
        //         {
        //             title: 'Open android store',
        //             action: AppVersionLockerButtonActionEnum.link,
        //             actionData: 'https://play.google.com/store/apps/details?id=dk.app.bricks'
        //         },
        //         {
        //             title: 'internal',
        //             action: AppVersionLockerButtonActionEnum.internalRedirect,
        //             actionData: '/form/76?group=45452'
        //         },
        //         {
        //             title: 'void',
        //             action: AppVersionLockerButtonActionEnum.void,
        //         }
        //     ]
        // } as AppVersionLockerInterface);
        return from(App.getInfo()).pipe(
            switchMap(appInfo => {
                return from(Device.getInfo()).pipe(
                    switchMap(deviceInfo => {
                        return this.appVersionLockerService.check({
                            appVersion: appInfo.version,
                            osVersion: deviceInfo.osVersion,
                            deviceModel: deviceInfo.model,
                            platform: deviceInfo.platform,
                            webViewVersion: deviceInfo.webViewVersion
                        })
                    })
                )
            })
        )
    }
}
