import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {MyCalendarService} from "../../services/my-calendar.service";
import {catchError, filter, map, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {MetricReportGroupService} from "@ypa/data-access/metric-report-group";
import {MetricReportGroupStatusEnum} from "@ypa/enums/metric-report-group-status";
import {CalendarNextActionTypeEnum} from "../../enums/calendar-next-action-type.enum";
import {NavigationEnd, Router} from "@angular/router";
import {FormTypeEnum} from "@ypa/enums/form-type";
import {CalendarEventsService} from "../../services/calendar-events.service";
import {Platform} from "@ionic/angular";
import {MetricReportGroupInterface} from "@ypa/types/metric-report-group";
import {CalendarUpdateInitiationTypeEnum} from "@ypa/features/calendar";
import {GetByTypeEnum} from "@ypa/enums/get-by-type";

@Component({
    selector: 'ypa-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {

    private readonly calendarOffsetAdditionHeight = 50;
    readonly calendarNextActionTypeEnum = CalendarNextActionTypeEnum;

    viewDate = new Date();
    events$: Observable<any[]> = of([]);
    selectedDate$: Observable<Date>;

    isShown = false;
    isLoaderShown = false;

    @ViewChild('calendarHolder') calendarHolder: ElementRef;

    private subscription = new Subscription();

    constructor(
        private myCalendarService: MyCalendarService,
        private calendarEventsService: CalendarEventsService,
        private metricReportGroupService: MetricReportGroupService,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private platform: Platform
    ) {
        //
    }

    ngOnInit(): void {
        this.handleSelectedDate();
        this.listenRoutingChange();
        this.handleResumeEvent();
        this.calendarEventsService.updateStart(CalendarUpdateInitiationTypeEnum.dateChange);
    }

    ngAfterViewInit() {
        this.handleResumeEvent();
        this.handleCalendarOpenClose();
        this.handleMetricReportGroupData();
        this.close();
        this.hideCalendar(); // todo check this line
        this.cdRef.detectChanges();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    close() {
        this.myCalendarService.hide();
    }

    dayClick(day: any) {
        this.myCalendarService.selectDate(new Date(day.date));
        this.close();
    }

    isAllMetricGroupsPassed(events: any[]): boolean {
        return !!events.length && events.every(e => e.data.status === MetricReportGroupStatusEnum.done);
    }

    isDatesEqual(date1: string, date2: Date) {
        return new Date(date1).toDateString() === date2.toDateString();
    }

    handleSelectedDate() {
        this.selectedDate$ = this.myCalendarService.selectedDate$.pipe(
            tap(date => {
                this.viewDate = date
            })
        );
    }

    backToToday() {
        this.setTodayDate();
    }

    getDayBadge(day: any): number {
        if (!day || !day.events) {
            return 0
        }

        return day.events.filter((event: any) => {
            const metricReportGroup = event?.data as MetricReportGroupInterface;
            if (!metricReportGroup) {
                return false
            }

            return metricReportGroup.status === MetricReportGroupStatusEnum.new && metricReportGroup.formType === FormTypeEnum.timeIn;
        }).length;
    }

    private handleMetricReportGroupData() {
        this.events$ = this.calendarEventsService.updateInitiation$.pipe(
            // this.events$ = this.systemEventsService.calendarGlobalUpdate$.pipe(
            //     filter(eventType => {
            //         return eventType === CalendarGlobalUpdateEventTypeEnum.dateChangeEvent
            //     }),
            switchMap(() => {
                return this.myCalendarService.monthChange$
            }),
            tap(() => {
                this.isLoaderShown = true;
                if (this.isShown) {
                    setTimeout(() => {
                        this.showCalendar();
                    })
                }
                this.cdRef.detectChanges();
            }),
            switchMap(() => {
                return this.getDateRageData();
            }),
            tap(() => {
                this.isLoaderShown = false;
                this.calendarEventsService.updateEnd()
            }),
            catchError(error => {
                this.calendarEventsService.updateEnd()
                this.isLoaderShown = false;
                console.error(error);
                return of([])
            })
        )
    }

    private getDateRageData(): Observable<any[]> {
        const month = this.myCalendarService.getCalendarMonthRage();
        return this.metricReportGroupService.getByDateRangeAndType(
            month.firstDay,
            month.lastDay,
            [
                FormTypeEnum.always,
                FormTypeEnum.followUp,
                FormTypeEnum.timeIn
            ],
            GetByTypeEnum.force,
            true
        ).pipe(
            switchMap(() => this.metricReportGroupService.getByDateRangeAndType(
                    month.firstDay,
                    month.lastDay,
                    [
                        FormTypeEnum.timeIn,
                        FormTypeEnum.always
                    ],
                    GetByTypeEnum.repository
                    , true
                )
            ),
            map(data => {
                return data.filter(x => (
                        x.status === MetricReportGroupStatusEnum.done ||
                        x.status === MetricReportGroupStatusEnum.new
                    )
                ).map(d => ({
                    title: '',
                    start: d.dateAt,
                    data: d
                }));
            })
        )
    }

    private handleCalendarOpenClose() {
        this.subscription.add(
            this.myCalendarService.isShown$.subscribe(state => {
                this.isShown = state;
                if (state) {
                    this.showCalendar();
                } else {
                    this.hideCalendar()
                }
            })
        );
    }

    private handleResumeEvent() {
        this.subscription.add(
            this.platform.resume.subscribe(() => {
                this.setTodayDate();
            })
        );
    }

    private setTodayDate() {
        const calendarDate = this.myCalendarService.getSelectedDate();
        const nowDate = new Date();

        if (
            calendarDate.getFullYear() !== nowDate.getFullYear() ||
            calendarDate.getMonth() !== nowDate.getMonth() ||
            calendarDate.getDate() !== nowDate.getDate()
        ) {
            this.myCalendarService.selectDate(nowDate);
            this.calendarEventsService.updateStart()
        }
    }

    private hideCalendar() {
        this.calendarHolder.nativeElement.style.transform = `translateY(0px)`;
    }

    private showCalendar() {
        const offset = this.calendarHolder.nativeElement.clientHeight + this.calendarOffsetAdditionHeight;
        this.calendarHolder.nativeElement.style.transform = `translateY(${offset + 80}px)`;
        this.cdRef.detectChanges()
    }

    private listenRoutingChange() {
        this.subscription.add(
            this.router.events.pipe(
                filter((event: any) => event instanceof NavigationEnd)
            ).subscribe((event: NavigationEnd) => {
                this.myCalendarService.hide();
            })
        );
    }
}
