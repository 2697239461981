import {Injectable} from '@angular/core';
import {createStore, select, withProps} from "@ngneat/elf";
import {CalendarNextActionTypeEnum} from "../enums/calendar-next-action-type.enum";
import {BehaviorSubject, Observable} from "rxjs";

const visibilityStore = createStore(
    {name: 'MY_CALENDAR_VISIBILITY'},
    withProps<{ data: boolean }>({data: false})
);

const selectedDateStore = createStore(
    {name: 'MY_CALENDAR_SELECTED_DATE'},
    withProps<Date>(new Date()),
);


@Injectable({providedIn: "root"})
export class MyCalendarService {
    isShown$ = visibilityStore.pipe(select((state) => state.data));

    selectedDate$ = selectedDateStore.pipe(select((state) => state));

    monthChange$:Observable<void>;

    private monthChangeSubject = new BehaviorSubject<void>(undefined);
    constructor(
    ) {
        this.monthChange$ = this.monthChangeSubject.asObservable();
        this.selectDate(new Date()); // new Date() as a default value in the elf store, does not work, so it sets in the constructor
    }

    show() {
        if (visibilityStore.getValue()?.data) {
            return;
        }
        visibilityStore.update(() => ({data: true}));
    }

    hide() {
        if (!visibilityStore.getValue()?.data) {
            return;
        }
        visibilityStore.update(() => ({data: false}));
    }

    selectDate(date: Date) {
        selectedDateStore.update(() => date);
    }

    getSelectedDate(): Date {
        return selectedDateStore.getValue();
    }

    getCalendarMonthRage(): { firstDay: Date, lastDay: Date } {
        const calendarDate = this.getSelectedDate();

        const firstDay = new Date(new Date(calendarDate.getFullYear(), calendarDate.getMonth(), 1).getTime() - 7 * 24 * 60 * 60 * 1000);
        const lastDay = new Date(new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1, 0).getTime() + 7 * 24 * 60 * 60 * 1000);

        return {firstDay, lastDay};
    }

    next(type: CalendarNextActionTypeEnum = CalendarNextActionTypeEnum.day) {
        const day = this.getSelectedDate();

        const nextDate = (
            type === CalendarNextActionTypeEnum.day ?
                day.getDate() :
                day.getMonth()
        ) + 1;

        this.selectDate(new Date(
            type === CalendarNextActionTypeEnum.day ?
                day.setDate(nextDate) :
                day.setMonth(nextDate)
        ));

        if (type === CalendarNextActionTypeEnum.month) {
            this.monthChangeSubject.next();
        }
    }

    previous(type: CalendarNextActionTypeEnum = CalendarNextActionTypeEnum.day) {
        const day = this.getSelectedDate();

        const prevDate = (
            type === CalendarNextActionTypeEnum.day ?
                day.getDate() :
                day.getMonth()
        ) - 1;

        this.selectDate(new Date(
            type === CalendarNextActionTypeEnum.day ?
                day.setDate(prevDate) :
                day.setMonth(prevDate)
        ));

        if (type === CalendarNextActionTypeEnum.month) {
            this.monthChangeSubject.next();
        }
    }
}
