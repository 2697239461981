export class DateHelper {
    /**
     * week days starts from sunday
     * @private
     */
    private static weekDays = [
        'shared.day.short.sunday',
        'shared.day.short.monday',
        'shared.day.short.tuesday',
        'shared.day.short.wednesday',
        'shared.day.short.thursday',
        'shared.day.short.friday',
        'shared.day.short.saturday'
    ];

    /**
     * get day of week by order number
     * 0 - Sunday
     * ...
     * 6 - Saturday
     * @param orderNumber
     */
    static getWeekDayByOrderNumber(orderNumber: number): string {
        if (orderNumber > 6 || orderNumber < 0) {
            return 'impossible day of week';
        }

        return DateHelper.weekDays[orderNumber]
    }
}
