import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {ClubReminderInterface, ClubReminderParams} from "@ypa/types/club-reminder";

const name = 'REMINDER';

@Injectable({providedIn: "root"})
export class ReminderRepository extends AbstractEntityRepository<ClubReminderInterface, ClubReminderParams> {
    protected readonly store = createStore(
        {name},
        withEntities<ClubReminderInterface>()
    );
}
