import {Injectable} from "@angular/core";
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {BehaviorSubject, Subject} from "rxjs";
import {PushActionService} from "./push-action.service";
import {Platform} from "@ionic/angular";

@Injectable({providedIn: "root"})
export class PushService {

    private notificationSubject = new Subject<PushNotificationSchema>();
    tokenSubject = new BehaviorSubject<string | null>(null);
    notification$ = this.notificationSubject.asObservable();
    token$ = this.tokenSubject.asObservable();

    constructor(
        private pushActionService: PushActionService,
        private platform: Platform
    ) {
        //
    }

    init() {
        PushNotifications.checkPermissions().then(permissions => {
            // console.log('push checkPermissions success', permissions);
            // if (permissions.receive !== 'granted') {
                PushNotifications.requestPermissions().then(result => {
                    // console.log('push requestPermissions success', result);
                    if (result.receive === 'granted') {
                        // Register with Apple / Google to receive push via APNS/FCM
                        PushNotifications.register().then();
                    } else {
                        // Show some error
                    }
                }).catch(error => {
                    console.log('push requestPermissions error', error);
                });
            // }
        }).catch(error => {
            console.log('push checkPermissions error', error);
        });


        // // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                this.tokenSubject.next(token.value);
                // console.log('Push registration success, token: ', token.value);
                // alert('Push registration success, token: ' + token.value);
            }
        );

        // Some issue with our setup and push will not work
        // PushNotifications.addListener('registrationError',
        //   (error: any) => {
        //     // alert('Error on registration: ' + JSON.stringify(error));
        //   }
        // );

        // Show us the notification payload if the app is open on our device
        // PushNotifications.addListener('pushNotificationReceived',
        //   (notification: PushNotificationSchema) => {
        //       console.log('pushNotificationReceived', notification);
        //
        //       // this.notificationSubject.next(notification);
        //     // setTimeout(() => {
        //     //   debugger;
        //     //
        //     //   if (this.platform.is('ios')) {
        //     //     // JSON.parse(notification.data.customData)
        //     //   }
        //       alert('Push received: ' + JSON.stringify(notification));
        //     // }, 3000);
        //   }
        // );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                // console.log('pushNotificationActionPerformed', notification);
                this.notificationSubject.next(notification.notification);
                this.pushActionService.handlePushMessageAction(notification);
                // this.removeDeliveredNotification(notification.notification);
                // alert('Push action performed: ' + JSON.stringify(notification));
            }
        );
    }

    private removeDeliveredNotification(notification: PushNotificationSchema) {
        PushNotifications.removeDeliveredNotifications({
            notifications: [notification]
        }).then()
    }

    private createChannel() {
        if (this.platform.is('android')) {
            PushNotifications.createChannel({
                id: 'main_channel',
                name: 'Main Channel',
                vibration: true,
                visibility: 1,
                importance: 4
            }).then();
        }
    }
}
