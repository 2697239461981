import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarCommonModule, CalendarMonthModule } from 'angular-calendar';
import { WeekDayModule } from '@ypa/pipes/week-day';
import { CalendarDateComponent } from './components/calendar-date/calendar-date.component';
import {IonicModule} from "@ionic/angular";
import {LoaderComponent} from "@ypa/ui/pwa/loader";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [CalendarComponent, CalendarDateComponent],
    imports: [
        CommonModule,
        CalendarMonthModule,
        CalendarCommonModule,
        WeekDayModule,
        IonicModule,
        TranslateModule,
        LoaderComponent
    ],
    exports: [CalendarComponent, CalendarDateComponent],
    providers: [
    ],
})
export class CalendarModule {}
