import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HeaderModule} from './features/header/header.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {UserAuthService} from '@ypa/data-access/user';
import {FooterModule} from './features/footer/footer.module';
import {FormHelper} from './helpers/form.helper';
import {of, take} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {AuthInterceptor} from '@ypa/interceptors/auth';
import {UserAuthRepository} from '@ypa/state-management/shared/auth';
import {AuthGuard} from './guards/auth.guard';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CalendarModule as AsCustomCalendarModule} from '@ypa/features/calendar';
import {TimezonesService} from '@ypa/data-access/shared/timezone';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {JsonLoaderService} from '@ypa/i18n';
import {LanguageCodeEnum} from '@ypa/enums/language-code';
import {LanguageCurrentService} from '@ypa/data-access/language';
import {registerLocaleData} from '@angular/common';
import myLocaleDk from '@angular/common/locales/da';
import myLocaleEn from '@angular/common/locales/en';
import {FormErrorsModule} from "./ui/form-errors/form-errors.module";
import {ReactiveFormsModule} from "@angular/forms";
import {AppVersionGuideService} from './data-access/app-version-guide/app-version-guide.service';

registerLocaleData(myLocaleDk);
registerLocaleData(myLocaleEn);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HeaderModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FooterModule,
        AsCustomCalendarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: JsonLoaderService,
            },
            defaultLanguage: localStorage.getItem('locale')
                ? localStorage.getItem('locale')
                : LanguageCodeEnum.Danish,
            // defaultLanguage: environment.defaultLanguage
        }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        FormErrorsModule,
        ReactiveFormsModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (
                userAuthService: UserAuthService,
                userAuthRepository: UserAuthRepository,
                languageCurrentService: LanguageCurrentService
            ) => {
                return () =>
                    userAuthService.get(true).pipe(
                        take(1),
                        catchError(() => of(null)),
                        switchMap((userAuth) =>
                            languageCurrentService.getLanguageList().pipe(
                                take(1),
                                catchError(() => of(null)),
                                switchMap(() => of(userAuth))
                            )
                        )
                    );
            },
            deps: [UserAuthService, UserAuthRepository, LanguageCurrentService],
            multi: true,
        },
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        FormHelper, // todo refactor this
        AuthGuard,
        TimezonesService,
        AppVersionGuideService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
