import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {MetricTagInterface, MetricTagParams} from "@ypa/types/metric-tag";

const name = 'METRIC_TAG';

@Injectable({providedIn: "root"})
export class MetricTagRepository extends AbstractEntityRepository<MetricTagInterface, MetricTagParams> {
    protected readonly store = createStore(
        {name},
        withEntities<MetricTagInterface>()
    );
}
