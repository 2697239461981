import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {HttpClient} from "@angular/common/http";
import {Observable, tap, throwError} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {BaseMetricTagInterface, MetricTagInterface, MetricTagParams} from "@ypa/types/metric-tag";
import {MetricTagRepository} from "@ypa/state-management/shared/metric-tag";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class MetricTagService extends AbstractEntityRepositoryServices<MetricTagInterface, BaseMetricTagInterface, MetricTagParams> {

    constructor(
        private http: HttpClient,
        private metricTagRepository: MetricTagRepository
    ) {
        super(metricTagRepository);
    }

    protected getAllReq(): Observable<MetricTagInterface[]> {
        return this.http.get<MetricTagInterface[]>(`${environment.apiUrl}/metric-tag`);
    }

    protected createReq(form: BaseMetricTagInterface | Partial<MetricTagInterface>): Observable<MetricTagInterface> {
        return this.http.post<MetricTagInterface>(`${environment.apiUrl}/metric-tag`, form);
    }

    protected updateReq(id: number, form: Partial<MetricTagInterface>): Observable<MetricTagInterface> {
        return throwError(() => new Error('Update metric-tag api is not implemented'))
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/metric-tag/${id}`);
    }

    protected getByIdReq(id: number): Observable<MetricTagInterface> {
        return throwError(() => new Error('Get tag by id api is not implemented'))
    }

    protected getByReq(params: MetricTagParams): Observable<MetricTagInterface[]> {
        return this.http.get<MetricTagInterface[]>(`${environment.apiUrl}/metric-tag`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<MetricTagInterface>): Observable<EntityListInterface<MetricTagInterface>> {
        throw new Error("Method not implemented.");
    }
}
