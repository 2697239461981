import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseBaselineTestInterface, BaselineTestInterface, BaselineTestParams} from "@ypa/types/baseline-test";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaselineTestRepository} from "@ypa/state-management/shared/baseline-test";
import {Observable, tap} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {EntityListInterface} from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class BaselineTestService extends AbstractEntityRepositoryServices<BaselineTestInterface, BaseBaselineTestInterface, BaselineTestParams> {
    constructor(
        private http: HttpClient,
        private baselineTestRepository: BaselineTestRepository
    ) {
        super(baselineTestRepository);
    }

    getMy(clubId: number): Observable<BaselineTestInterface[]> {
        return this.getMyReq(clubId).pipe(
            tap(data => this.baselineTestRepository.upsert(data))
        )
    }

    private getMyReq(clubId: number): Observable<BaselineTestInterface[]> {
        return this.http.get<BaselineTestInterface[]>(`${environment.apiUrl}/baseline-test/my/${clubId}`);
    }

    protected createReq(form: Partial<BaselineTestInterface> | BaseBaselineTestInterface): Observable<BaselineTestInterface> {
        return this.http.post<BaselineTestInterface>(`${environment.apiUrl}/baseline-test`, form);
    }

    protected getAllReq(): Observable<BaselineTestInterface[]> {
        return this.http.get<BaselineTestInterface[]>(`${environment.apiUrl}/baseline-test`);
    }

    protected getByIdReq(id: number): Observable<BaselineTestInterface> {
        return this.http.get<BaselineTestInterface>(`${environment.apiUrl}/baseline-test/${id}`);
    }

    protected getByReq(params: BaselineTestParams): Observable<BaselineTestInterface[]> {
        return this.http.get<BaselineTestInterface[]>(`${environment.apiUrl}/baseline-test`, {params: params as HttpParams});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/baseline-test/${id}`);
    }

    protected updateReq(id: number, form: Partial<BaselineTestInterface>): Observable<BaselineTestInterface> {
        return this.http.patch<BaselineTestInterface>(`${environment.apiUrl}/baseline-test/${id}`, form);
    }

    protected updateListReq(list: EntityListInterface<BaselineTestInterface>): Observable<EntityListInterface<BaselineTestInterface>> {
        throw new Error("Method not implemented.");
    }
}
