import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {AbstractErrorsPanelComponent} from "@ypa/ovdal/ovdal-errors";

@Component({
    selector: 'ypa-form-errors',
    templateUrl: './form-errors.component.html',
    styleUrls: ['./form-errors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger("inOutPaneAnimation", [
            transition(":enter", [
                style({opacity: 0}),
                animate(
                    "350ms ease",
                    style({opacity: 1})
                )
            ]),
            transition(":leave", [
                style({opacity: 1}),
                animate(
                    "340ms ease",
                    style({opacity: 0})
                )
            ])
        ])
    ]
})
export class FormErrorsComponent extends AbstractErrorsPanelComponent {
    @Input() styleClass: string;
    @Input() color: "danger" | "dark" | "light" | "medium" | "primary" | "secondary" | "success" | "tertiary" | "warning" | string = "danger"
}
