import {Injectable} from '@angular/core';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseRoleInterface} from "@ypa/types/role";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {BaseEntityParams, EntityListInterface} from "@ypa/types/base-entity";
import {
    RoleMetricRestrictionTagInterface,
    RoleMetricRestrictionTagParams
} from "@ypa/types/role-metric-restriction-tag";
import {RoleMetricRestrictionTagRepository} from "@ypa/state-management/shared/role-metric-restriction-tag";

@Injectable({providedIn: "root"})
export class RoleMetricRestrictionTagService extends AbstractEntityRepositoryServices<RoleMetricRestrictionTagInterface, BaseRoleInterface, RoleMetricRestrictionTagParams> {

    constructor(
        private http: HttpClient,
        private roleMetricRestrictionTagRepository: RoleMetricRestrictionTagRepository
    ) {
        super(roleMetricRestrictionTagRepository)
    }

    protected getAllReq(): Observable<RoleMetricRestrictionTagInterface[]> {
        return this.http.get<RoleMetricRestrictionTagInterface[]>(`${environment.apiUrl}/role-metric-restriction-tag`);
    }

    protected createReq(form: BaseRoleInterface | Partial<RoleMetricRestrictionTagInterface>): Observable<RoleMetricRestrictionTagInterface> {
        return this.http.post<RoleMetricRestrictionTagInterface>(`${environment.apiUrl}/role-metric-restriction-tag`, form);
    }

    protected updateReq(id: number, form: Partial<RoleMetricRestrictionTagInterface>): Observable<RoleMetricRestrictionTagInterface> {
        return this.http.patch<RoleMetricRestrictionTagInterface>(`${environment.apiUrl}/role-metric-restriction-tag/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete<null>(`${environment.apiUrl}/role-metric-restriction-tag/${id}`);
    }

    protected getByIdReq(id: number): Observable<RoleMetricRestrictionTagInterface> {
        return this.http.get<RoleMetricRestrictionTagInterface>(`${environment.apiUrl}/role-metric-restriction-tag/${id}`);
    }

    protected getByReq(params: BaseEntityParams): Observable<RoleMetricRestrictionTagInterface[]> {
        return this.http.get<RoleMetricRestrictionTagInterface[]>(`${environment.apiUrl}/role-metric-restriction-tag`, {params: params as {}});
    }

    protected override updateListReq(list: EntityListInterface<RoleMetricRestrictionTagInterface>): Observable<EntityListInterface<RoleMetricRestrictionTagInterface>> {
        throw new Error('Method not implemented.');
    }
}
