import { MetricReportGroupStatusEnum } from "@ypa/enums/metric-report-group-status";
import { BaseEntity, BaseEntityInterface } from "@ypa/types/base-entity";
import { Type } from "class-transformer";
import { FormTypeEnum } from "@ypa/enums/form-type";
import { BaseTranslationFieldInterface } from "@ypa/types/translation-field";
import { BaseMetricReportInterface } from '@ypa/types/metric-report'

export interface WithoutFormMetricReportGroupInterface {
    userId?: number;
    taskId?: number;
    dateAt?: Date | string;
    comment?: string;
    status?: MetricReportGroupStatusEnum;
}

export interface BaseMetricReportGroupInterface extends WithoutFormMetricReportGroupInterface {
    formId: number;
    formName?: BaseTranslationFieldInterface;
    formDescription?: BaseTranslationFieldInterface;
    formIcon?: string;
    formType?: FormTypeEnum;
}

export interface MetricReportGroupInterface extends BaseMetricReportGroupInterface, BaseEntityInterface {
    formIcon: string;
    formType: FormTypeEnum;
}

export interface UiMetricReportGroupInterface extends MetricReportGroupInterface {
    isScheduleChanged: boolean;
    taskPersonalId: number;
}

export class MetricReportGroup extends BaseEntity implements MetricReportGroupInterface {
    userId: number;
    formId: number;
    taskId: number;
    timetableReportId?: number;
    exerciseReportId?: number;
    @Type(() => Date)
    dateAt: Date | string;
    comment: string;
    status: MetricReportGroupStatusEnum;
    formIcon: string;
    formName: BaseTranslationFieldInterface;
    formType: FormTypeEnum;
    reports?: BaseMetricReportInterface[];

}

