<ng-container *ngIf="date$ | async as date">
    <div class="calendar-date">
        <div *ngIf="isPrevForwardButtons" (click)="prev()" class="button button-prev">
            <i class="fas fa-chevron-left"></i>
        </div>

        <div (click)="showCalendar()" class="calendar-date--title">
            {{date | date: dateFormat : null :userLocale}}
            <ng-container *ngIf="isShowCalendarButton">
                <div class="show-calendar-hint">
                    <div class="icon-container">
                        <ion-icon name="calendar-outline" class="calendar-icon"></ion-icon>
                        <!--                        <ion-badge color="danger" class="calendar-badge">!</ion-badge>-->
                    </div>
                    {{'app.athlete.calendar.show.title' | translate}}
                </div>
            </ng-container>
        </div>

        <div *ngIf="isPrevForwardButtons" (click)="next()" class="button button-next">
            <i class="fas fa-chevron-right"></i>
        </div>

    </div>

</ng-container>
