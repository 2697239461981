import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@ypa/constants/environments';
import {
    BaseInvitationInterface,
    FileInvitation,
    InvitationInterface,
    InvitationParams, PersonalInvitationInterface
} from '@ypa/types/invitation';
import {Observable, of, switchMap, tap, throwError} from 'rxjs';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {InvitationRepository} from "@ypa/state-management/shared/invitation";
import { EntityListInterface } from '@ypa/types/base-entity';

@Injectable({providedIn: "root"})
export class InvitationService extends AbstractEntityRepositoryServices<InvitationInterface, BaseInvitationInterface, InvitationParams> {

    constructor(
        private http: HttpClient,
        private invitationRepository: InvitationRepository
    ) {
        super(invitationRepository);
    }

    getMyInvitation(): Observable<InvitationInterface> {
        return this.http.get<InvitationInterface>(`${environment.apiUrl}/invitation/start-on-boarding`);
    }

    sendSmsCodeByPhone(phone: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/invitation/try-register-by-sms`, {phone})
    }

    getByToken(token: string): Observable<InvitationInterface> {
        return this.invitationRepository.getByToken(token).pipe(
            switchMap(invitation => {
                if (invitation) {
                    return of(invitation)
                }
                return this.getByTokenReq(token).pipe(
                    tap(data => this.invitationRepository.upsert(data))
                );
            })
        )
    }

    sendFileInvitation(form: FileInvitation, fileData: File): Observable<InvitationInterface[]> {
        form.file = fileData;
        const formData: FormData = this.generateFormData(form);

        return this.http.post<InvitationInterface[]>(`${environment.apiUrl}/invitation/import`, formData).pipe(
            tap(data => this.invitationRepository.upsert(data))
        );
    }

    sendInvitation<T extends PersonalInvitationInterface>(form: T): Observable<InvitationInterface[]> {
        return this.http.post<InvitationInterface[]>(`${environment.apiUrl}/invitation`, form).pipe(
            tap(data => this.invitationRepository.upsert(data))
        );
    }

    resendInvitation(id: number): Observable<any> {
        return this.http.get(`${environment.apiUrl}/invitation/${id}/send`);
    }

    private getByTokenReq(token: string): Observable<InvitationInterface> {
        return this.http.get<InvitationInterface>(`${environment.apiUrl}/invitation/${token}`);
    }

    protected getAllReq(): Observable<InvitationInterface[]> {
        return this.http.get<InvitationInterface[]>(`${environment.apiUrl}/invitation`);
    }

    protected createReq(form: BaseInvitationInterface | Partial<InvitationInterface>): Observable<InvitationInterface> {
        return this.http.post<InvitationInterface>(`${environment.apiUrl}/invitation`, form);
    }

    protected updateReq(id: number, form: Partial<InvitationInterface>): Observable<InvitationInterface> {
        return throwError(() => new Error('Update invitation is not implemented'))
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/invitation/${id}`);
    }

    protected getByIdReq(id: number): Observable<InvitationInterface> {
        return throwError(() => new Error('Get invitation by id is not implemented'));
    }

    protected getByReq(params: InvitationParams): Observable<InvitationInterface[]> {
        return this.http.get<InvitationInterface[]>(`${environment.apiUrl}/invitation`, {params: params as {}});
    }

    protected updateListReq(list: EntityListInterface<InvitationInterface>): Observable<EntityListInterface<InvitationInterface>> {
        throw new Error('Method not implemented.');
    }

    /**
     * Generate FormData
     * @param file: File
     * @return FormData
     */
    private generateFormData(data:any): FormData {
        const formData = new FormData();

        for (const key of Object.keys(data)) {
            const value = data[key];
            formData.append(key, value);
        }

        return formData;
    }
}
