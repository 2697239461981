import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {BaseOnBoardingInterface, OnBoardingInterface, OnBoardingParams} from "@ypa/types/on-boarding";
import {HttpClient} from "@angular/common/http";
import {OnBoardingRepository} from "@ypa/state-management/shared/on-boarding";
import {Observable, tap} from "rxjs";
import {environment} from "@ypa/constants/environments";
import { EntityListInterface } from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class OnBoardingService extends AbstractEntityRepositoryServices<OnBoardingInterface, BaseOnBoardingInterface, OnBoardingParams> {

    constructor(
        private http: HttpClient,
        private onBoardingRepository: OnBoardingRepository
    ) {
        super(onBoardingRepository);
    }

    getMy(clubId: number): Observable<OnBoardingInterface[]> {
        return this.getMyReq(clubId).pipe(
            tap(data => this.onBoardingRepository.upsert(data))
        );
    }

    getMyReq(clubId: number): Observable<OnBoardingInterface[]> {
        return this.http.get<OnBoardingInterface[]>(`${environment.apiUrl}/on-boarding/my/${clubId}`);
    }

    protected createReq(form: Partial<OnBoardingInterface> | BaseOnBoardingInterface): Observable<OnBoardingInterface> {
        return this.http.post<OnBoardingInterface>(`${environment.apiUrl}/on-boarding`, form);
    }

    protected getAllReq(): Observable<OnBoardingInterface[]> {
        return this.http.get<OnBoardingInterface[]>(`${environment.apiUrl}/on-boarding`);
    }

    protected getByIdReq(id: number): Observable<OnBoardingInterface> {
        return this.http.get<OnBoardingInterface>(`${environment.apiUrl}/on-boarding/${id}`);
    }

    protected getByReq(params: OnBoardingParams): Observable<OnBoardingInterface[]> {
        return this.http.get<OnBoardingInterface[]>(`${environment.apiUrl}/on-boarding`, {params: params as {}});
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/on-boarding/${id}`);

    }

    protected updateReq(id: number, form: Partial<OnBoardingInterface>): Observable<OnBoardingInterface> {
        return this.http.patch<OnBoardingInterface>(`${environment.apiUrl}/on-boarding/${id}`, form);
    }

    protected updateListReq(list: EntityListInterface<OnBoardingInterface>): Observable<EntityListInterface<OnBoardingInterface>> {
        throw new Error("Method not implemented.");
    }
}
