import {Injectable} from "@angular/core";
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {ControlsOf, FormArray, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";
import {UiBaselineTestInterface} from "@ypa/types/baseline-test";
import {BaselineTestInjuryFormService} from "@ypa/data-access/baseline-test-injury";

@Injectable()
export class BaselineTestFormService extends AbstractFormService<UiBaselineTestInterface> {

    constructor(
        private baselineTestInjuryFormService: BaselineTestInjuryFormService
    ) {
        super();
    }

    getForm(params: Partial<UiBaselineTestInterface>): FormGroup<ControlsOf<Partial<UiBaselineTestInterface>>> {
        let form = {};

        if (params && params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        if (params && params.ownerClubId !== null && typeof params.ownerClubId !== "undefined") {
            form = {
                ...form,
                ...{
                    ownerClubId: this.fb.control(params.ownerClubId),
                }
            }
        }

        return this.fb.group({
            ...form,
            ...{
                // ownerClubId: this.fb.control(params && params.ownerClubId ? params.ownerClubId : ('' as unknown as number)),
                tagId: this.fb.control(params && params.tagId ? params.tagId : ('' as unknown as number), [Validators.required]),
                formId: this.fb.control(params && params.formId ? params.formId : ('' as unknown as number), [Validators.required]),
                matchAllInjuries: this.fb.control(typeof params.matchAllInjuries !== "undefined" ? params.matchAllInjuries : true),
                injuries: this.baselineTestInjuryFormService.getFormArray(),
                deleted: this.fb.control(false)
            }
        }) as FormGroup<ControlsOf<Partial<UiBaselineTestInterface>>>;
    }


    getFormArray(): FormArray<Partial<UiBaselineTestInterface>> {
        return this.fb.array([]);
    }
}
