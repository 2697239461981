<div class="main-calendar-holder">
    <div #calendarHolder class="calendar-container">
        <ng-container *ngIf="selectedDate$ | async as selectedDate">
            <div class="calendar-date-holder">
                <ypa-calendar-date
                    [nextActionType]="calendarNextActionTypeEnum.month"
                    [isShowCalendarButton]="false"
                ></ypa-calendar-date>
            </div>
            <ng-container *ngIf="events$  | async as events; else loader">
                <mwl-calendar-month-view
                    [weekStartsOn]="1"
                    [headerTemplate]="customHeader"
                    [cellTemplate]="customCellTemplate"
                    [weekendDays]="[0,1,2,3,4,5,6]"
                    [viewDate]="viewDate"
                    [events]="events">
                </mwl-calendar-month-view>
            </ng-container>
            <ng-template #loader>
                <div class="calendar-container-loader-holder">
                    <ypa-loader></ypa-loader>
                </div>
            </ng-template>
            <div class="calendar-container-loader-holder" *ngIf="isLoaderShown">
                <ypa-loader></ypa-loader>
            </div>

            <ng-template #customHeader let-days="days">
                <div class="cal-cell-row cal-header">
                    <div *ngFor="let day of days" [tabindex]="day.day"
                         class="cal-cell cal-today cal-weekend">{{day.day | weekDay | translate}}</div>
                </div>
            </ng-template>

            <ng-template #customCellTemplate let-day="day" let-locale="locale">
                <div (click)="dayClick(day)">
                    <!--                <div>{{day | json}}</div>-->
                    <!--        <div>{{day.events}}</div>-->

                    <!--        <div class="cal-cell-top" [ngClass]="{'has-events': !!day.events.length}">-->
                    <div class="cal-cell-top"
                         [ngClass]="{'has-events': !!day.events.length, 'success-events': isAllMetricGroupsPassed(day.events)}">
                        <span class="cal-day-badge" *ngIf="getDayBadge(day) > 0">{{ getDayBadge(day) }}</span>
                        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                    </div>
                    <!--        <div class="cal-events" *ngIf="day.events">-->
                    <!--            <div class="cal-event" *ngFor="let event of day.events"></div>-->
                    <!--        </div>-->

                    <div class="cal-events" *ngIf="isDatesEqual(day.date, selectedDate)">
                        <div class="cal-event"></div>
                    </div>
                    <div class="cell-totals">
                        <span
                            *ngFor="let group of day.eventGroups"
                            class="badge badge-{{ group[0] }}">
                          {{ group[1].length }}
                        </span>
                    </div>
                </div>
            </ng-template>

            <div class="back-to-today" (click)="backToToday()">
                <div class="icon-container">
                    <ion-icon name="today-outline" class="calendar-icon"></ion-icon>
                </div>
                {{'app.athlete.calendar.back.today' | translate}}
            </div>

            <div (click)="close()" class="close-btn">
                <span class="fas fa-times"></span>
            </div>
        </ng-container>
    </div>
</div>
