import {Injectable} from "@angular/core";
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {BaseRestrictionTagInterface, RestrictionTagInterface} from "@ypa/types/restriction-tag";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";

const name = 'RESTRICTION_TAG';

@Injectable({providedIn: "root"})
export class RestrictionTagRepository extends AbstractEntityRepository<RestrictionTagInterface, BaseRestrictionTagInterface>{
    protected readonly store = createStore(
        {name},
        withEntities<RestrictionTagInterface>()
    );
}
