import {Injectable} from '@angular/core';
import {AbstractEntityRepository} from "@ypa/state-management/shared/abstract";
import {BaseEntityParams} from "@ypa/types/base-entity";
import {createStore} from "@ngneat/elf";
import {withEntities} from "@ngneat/elf-entities";
import {InjuryInterface} from "@ypa/types/injury";

const name = 'INJURY';

@Injectable({providedIn: "root"})
export class InjuryRepository extends AbstractEntityRepository<InjuryInterface, BaseEntityParams>{
    protected store= createStore(
        {name},
        withEntities<InjuryInterface>()
    );
}
