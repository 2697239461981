import {Injectable} from '@angular/core';
import {AbstractFormService} from "@ypa/data-access/abstract-form";
import {RolePermissionFormInterface} from "@ypa/types/role-permission";
import {ControlsOf, FormArray, FormGroup} from "@ngneat/reactive-forms";
import {Validators} from "@angular/forms";

@Injectable()
export class RolePermissionFormService extends AbstractFormService<RolePermissionFormInterface> {

    getForm(params: Partial<RolePermissionFormInterface>): FormGroup<ControlsOf<Partial<RolePermissionFormInterface>>> {

        let form = {};

        if (params.id !== null && typeof params.id !== "undefined") {
            form = {
                id: this.fb.control(params.id, [Validators.required]),
            }
        }

        return this.fb.group({
            ...form,
            roleId: this.fb.control(params.roleId),
            permissionKey: this.fb.control(params.permissionKey, Validators.required),
            label: this.fb.control(params.label),
            checked: this.fb.control({value: params.checked, disabled: true})
        })
    }

    getRolePermissionArray(): FormArray<Partial<RolePermissionFormInterface>> {
        return this.fb.array<Partial<RolePermissionFormInterface>>([]);
    }
}
