import {Injectable} from '@angular/core';
import {catchError, map, Observable, of, Subject, switchMap, tap, throwError} from 'rxjs';
import {
    BaseMetricReportGroupInterface,
    MetricReportGroup,
    MetricReportGroupInterface,
    MetricReportGroupParams
} from '@ypa/types/metric-report-group';
import {HttpClient} from '@angular/common/http';
import {environment} from '@ypa/constants/environments';
import {plainToClass, plainToInstance} from 'class-transformer';
import {MetricReportGroupRepository} from '@ypa/state-management/shared/metric-report-group';
import {FormTypeEnum} from '@ypa/enums/form-type';
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {MetricReportGroupStatusEnum} from "@ypa/enums/metric-report-group-status";
import {GetByTypeEnum} from "@ypa/enums/get-by-type";
import { EntityListInterface } from '@ypa/types/base-entity';
import { BaseMetricReportInterface } from '@ypa/types/metric-report';
import {ImportReportGroupInterface, ImportReportInterface} from "types/metric-report-group-import";
import * as moment from "moment";
import { MixpanelAnalyticsService } from '@ypa/libs/analytics';

@Injectable({providedIn: "root"})
export class MetricReportGroupService extends AbstractEntityRepositoryServices<MetricReportGroupInterface, BaseMetricReportGroupInterface, MetricReportGroupParams> {

    private metricReportGroupUpdateSubject = new Subject<MetricReportGroupInterface>();
    metricReportGroupUpdate$: Observable<MetricReportGroupInterface> = this.metricReportGroupUpdateSubject.asObservable();

    constructor(
        private http: HttpClient,
        private metricReportGroupRepository: MetricReportGroupRepository,
        private mixpanelAnalyticsService: MixpanelAnalyticsService
    ) {
        super(metricReportGroupRepository);
    }

    override create(form: Partial<BaseMetricReportGroupInterface>): Observable<MetricReportGroupInterface> {
        return super.create(form).pipe(
            tap((data: MetricReportGroupInterface) => {
                this.metricReportGroupUpdateSubject.next(data);
            })
        );
    }
    
    override update(id: number, form: Partial<MetricReportGroupInterface>): Observable<MetricReportGroupInterface> {
        return super.update(id, form).pipe(
            tap((data: MetricReportGroupInterface) => {
                this.metricReportGroupUpdateSubject.next(data);
            })
        );
    }

    public completeMetricReportGroupWithMetricReports(data : ImportReportInterface) : Observable<MetricReportGroupInterface> {
        
        // Do the call to the backend
        return this.http.post<MetricReportGroupInterface>(`${environment.apiUrl}/metric-report-group/complete-metric-report`, data).pipe(
            // update the frontend data
            tap((data: MetricReportGroupInterface) => {
                data.dateAt = moment(data.dateAt).toDate();
                this.updateRepositoryForEntity(data);
                this.mixpanelAnalyticsService.trackEvent('pwa', data.userId as number, 'metric', 'complete metric report');
                return this.metricReportGroupUpdateSubject.next(data);        
            })
        );
    }

    getByDateRangeAndType(from: Date, to: Date, types: FormTypeEnum[] = [], mode = GetByTypeEnum.force, withAllNewFollowUp = false): Observable<MetricReportGroupInterface[]> {
        const params: any = {};

        if (from) {
            params.dateAtFrom = from.toISOString();
        }

        if (to) {
            params.dateAtTo = to.toISOString();
        }

        if (types.length > 0) {
            params.formType = types;
        }

        return this.metricReportGroupRepository.getByDateRangeAndType(from, to, types, withAllNewFollowUp).pipe(
            switchMap(data => {
                if (mode === GetByTypeEnum.repository) {
                    return of(data);
                } else if (mode === GetByTypeEnum.regular && data.length > 0) {
                    return data
                }

                return this.http.get<MetricReportGroupInterface[]>(`${environment.apiUrl}/metric-report-group`, {params}).pipe(
                    catchError(() => of([])),
                    switchMap(reports => {
                        if (!withAllNewFollowUp) {
                            return of(reports);
                        }

                        return this.getByReq(
                            {
                                formType: FormTypeEnum.followUp,
                                status: MetricReportGroupStatusEnum.new
                            }
                        ).pipe(
                            catchError(() => of([])),
                            map(followUpReports => [...reports, ...followUpReports])
                        )
                    })
                )
            }),
            map(response => plainToInstance(MetricReportGroup, response as MetricReportGroup[])),
            tap(data => {
                if (mode === GetByTypeEnum.force) {
                    mode = GetByTypeEnum.repository;
                    this.metricReportGroupRepository.upsert(data);
                }
            }),
        )
    }

    // TODO not being used
    public getAllClubResponseRates(clubId: number): Observable<MetricReportGroupInterface[]> {
        return this.http.get(`${environment.apiUrl}/metric-report-group/club-responses/${clubId}`).pipe(
            map(response => plainToInstance(MetricReportGroup, response as MetricReportGroup[]))
        );
    }

    protected getAllReq(): Observable<MetricReportGroupInterface[]> {
        return this.http.get(`${environment.apiUrl}/metric-report-group`).pipe(
            map(response => plainToInstance(MetricReportGroup, response as MetricReportGroup[]))
        );
    }

    protected createReq(form: Partial<BaseMetricReportGroupInterface>): Observable<MetricReportGroupInterface> {
        return this.http.post<MetricReportGroupInterface>(`${environment.apiUrl}/metric-report-group`, form).pipe(
            map(response => plainToClass(MetricReportGroup, response))
        );
    }

    protected updateReq(id: number, form: Partial<MetricReportGroupInterface>): Observable<MetricReportGroupInterface> {
        return this.http.patch<MetricReportGroupInterface>(`${environment.apiUrl}/metric-report-group/${id}`, form).pipe(
            map(response => plainToClass(MetricReportGroup, response))
        );
    }

    protected removeReq(id: number): Observable<any> {
        return throwError(() => new Error('Remove metric report group api is not implemented on the server or not added to the service'));
    }

    protected getByIdReq(id: number): Observable<MetricReportGroupInterface> {
        return this.http.get<MetricReportGroupInterface>(`${environment.apiUrl}/metric-report-group/${id}`).pipe(
            map(response => plainToClass(MetricReportGroup, response)),
        );
    }

    protected getByReq(params: MetricReportGroupParams): Observable<MetricReportGroupInterface[]> {
        return this.http.get(`${environment.apiUrl}/metric-report-group`, {params: params as {}}).pipe(
            map(response => plainToInstance(MetricReportGroup, response as MetricReportGroup[])),
        );
    }

    protected override updateListReq(list: EntityListInterface<MetricReportGroupInterface>): Observable<EntityListInterface<MetricReportGroupInterface>> {
        throw new Error('Method not implemented.');
    }
}
