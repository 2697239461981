import {Injectable} from "@angular/core";
import {AbstractEntityRepositoryServices} from "@ypa/data-access/abstract-repository-services";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@ypa/constants/environments";
import {ReminderRepository} from "@ypa/state-management/shared/reminder";
import {BaseClubReminderInterface, ClubReminderInterface, ClubReminderParams} from "@ypa/types/club-reminder";
import { EntityListInterface } from "@ypa/types/base-entity";

@Injectable({providedIn: "root"})
export class ReminderService extends AbstractEntityRepositoryServices<ClubReminderInterface, BaseClubReminderInterface, ClubReminderParams> {

    constructor(
        private http: HttpClient,
        private reminderRepository: ReminderRepository
    ) {
        super(reminderRepository);
    }

    protected getAllReq(): Observable<ClubReminderInterface[]> {
        return this.http.get<ClubReminderInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/club-reminder`);
    }

    protected createReq(form: BaseClubReminderInterface | Partial<ClubReminderInterface>): Observable<ClubReminderInterface> {
        return this.http.post<ClubReminderInterface>(`${environment.apiUrl}/club-reminder`, form);
    }

    protected updateReq(id: number, form: Partial<ClubReminderInterface>): Observable<ClubReminderInterface> {
        return this.http.put<ClubReminderInterface>(`${environment.apiUrl}/club-reminder/${id}`, form);
    }

    protected removeReq(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/club-reminder/${id}`);
    }

    protected getByIdReq(id: number): Observable<ClubReminderInterface> {
        return this.http.get<ClubReminderInterface>(`${environment.apiUrl}/club-reminder/${id}`);
    }

    protected getByReq(params: ClubReminderParams): Observable<ClubReminderInterface[]> {
        return this.http.get<ClubReminderInterface[]>(`${environment.apiUrl}${environment.endpointMainPrefix}/club-reminder`, {params: params as HttpParams});
    }

    protected updateListReq(list: EntityListInterface<ClubReminderInterface>): Observable<EntityListInterface<ClubReminderInterface>> {
        throw new Error("Method not implemented.");
    }
}
